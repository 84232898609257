import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {marked} from "marked";

const PolicyViewer = () => {
    const {policyName}=useParams()
    const[content,setContent]=useState('')
    useEffect(()=>{
        fetch(`/policies/${policyName}.md`)
      .then((response) => response.text())
      .then((text) => setContent(marked(text)))
      .catch((error) => console.error('Error fetching policy:', error));
    },[])
  return (
    <div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

export default PolicyViewer