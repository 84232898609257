import { useState,  useLayoutEffect } from "react";
import { CreatePrimaryToken, FetchIndividual, getInfo } from "../utilities/calls";
import { useNavigate, Link, useParams,useLocation } from "react-router-dom";
import { AiOutlineImport, AiOutlineProfile } from "react-icons/ai";

export default function ContactSP() {
    const [individual, setIndividual] = useState({});
    const [addrList, setAddrList] = useState([]);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const { state: { text }} = useLocation();

    const [addressSelected, setAddressSelected] = useState();
    const { id } = useParams();
    const navigate = useNavigate();
    const [sent, setSent] = useState(true);



    useLayoutEffect(() => {
        const individualid = getInfo("IndividualID");
        console.log('individualid', individualid)
        FetchIndividual(individualid)
            .then((individualData) => {
                setIndividual(individualData);
                setAddrList(individualData.addresses);
                console.log(individualData)
                setSent(Object.keys(individualData.addresses).length === 1 ? false : true);
                if(Object.keys(individualData.addresses).length > 0) setAddressSelected(individualData.addresses[Object.keys(individualData.addresses)]);
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
            });
    }, []);

    const connect = () => {

        console.log(addressSelected)
        CreatePrimaryToken(id, addressSelected.id, individual.id).then(() => {
            setShowAlert(true);
            setAlertText("Contact shared with Service Provider");
            setSent(true);
        }).catch((err) => {
            console.error("Error contacting Service Provider", err);
            setAlertText("Error contacting Service Provider, please try again!");
            setShowAlert(true)
        })
    }

    const cancelFn = () => {
        navigate(`/search/?text=${text}`)
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        setAddressSelected(addrList[value]);
        setSent(false);
    };

    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label py-2">
                    <AiOutlineImport className="inline-icon" />
                    Connect with Service Provider
                </h1>
            </div>

            <div className="grid grid-cols-1">

                <form className="" id="address select">
                    <label className="form-label" htmlFor="addressselect">
                        Choose Address
                    </label>
                    {
                        Object.keys(addrList).length === 0 ?
                            <><p className="py-3">You don't have any address(es) yet.
                                <Link className="" to={{ pathname: "/AddAddressDetails" }} state={{ addresses: individual.addresses, previousPage: "/contactServiceProvider/" + id,searchedText:text }} >
                                    <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Address">
                                        <AiOutlineProfile className="inline-icon" /> Add New Address
                                    </button>
                                </Link>  </p></>
                            : <select defaultValue={`${addressSelected?.id || Object.keys(addrList)[0]}`} onChange={handleInputChange} className="form-input" name="addressselect" id="addressselect">
                                {Object.keys(addrList || {}).map((addressId, index) => {
                                    const address = addrList[addressId];
                                    return (<option key={`${address.id}`} value={`${address.id}`}>{`${address.tags?.atag?.Name}`}</option>);
                                })}
                            </select>
                    }
                </form>

                {/* {renderField("Message", "msg", "textarea",  messageVal , setMessageVal)} */}
                <div class="flex justify-center items-center space-x-4 py-2 ">
                    <button onClick={connect} style={{display: sent ? "none" : "block"}} type="submit" className="button-style positive-action">
                        Connect
                    </button>
                    {!sent ? (
                        <button onClick={cancelFn} className="button-style">Cancel</button>
                    ) : (
                        <button onClick={cancelFn} className="button-style">Close</button>
                    )}
                    <div
                        class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                        role="alert"
                        style={{ display: showAlert ? "block" : "none" }}
                    >
                        <span class="text-sm">{alertText} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}