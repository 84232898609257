import React, { useEffect, useState } from 'react';
import { GetAllContactsWithEmailAddresses} from "../utilities/email";
import { getInfo, GetJustWhereUsers } from "../utilities/calls";
import {AiOutlineSearch,AiOutlineMail} from "react-icons/ai";
import { isMobile } from '../utilities/functions';


const Contact = () => {
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [pageState, setPageState] = useState(0);
  const [justWhereUsers, setJustWhereUsers] = useState([]);


  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
   	const gmailaccesstoken = getInfo("Gmailaccesstoken");
	  if(gmailaccesstoken && gmailaccesstoken !== ""){
			    getContacts(gmailaccesstoken);
      } else {
    	  setGmailAuth();
        getContacts();
		  }
  }, []);
  
  const setGmailAuth = () => {
      if(sessionStorage.getItem('JWAUTH')){
        sessionStorage.setItem('pg_redirect', '/contacts')
        window.open(process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + "/gmail/authorization/call?&authtoken=" + sessionStorage.getItem('JWAUTH') , "_self");
      }
    }
    
    const getContacts=(gmailaccesstoken)=>{
      gmailaccesstoken =  gmailaccesstoken ? gmailaccesstoken : getInfo("Gmailaccesstoken");
      if(gmailaccesstoken && gmailaccesstoken !== "") {
       
      GetAllContactsWithEmailAddresses(gmailaccesstoken).then((response) => {
        setPageState(1);
          setContacts(response);
        }).catch((err) =>{
        console.log(err);
        setPageState(2);
      });
    } else {
      console.log("gmailaccesstoken is not present");
    } 
    }

    useEffect(() => {
      GetJustWhereUsers(contacts.map((contact) => {return contact.email})).then((jwUsersArr) =>{
          setJustWhereUsers(jwUsersArr.validUserIds);
      }).catch((err) => {
        console.log('Error in GetJustWhereUsers', err);
      })
    }, [contacts]);

    
      
      const sendInviteEmail = (email) => {
        const origin = window.location.origin;
        const to =  encodeURIComponent(email);
        const subject = encodeURIComponent("I am on JustWhere, please join JustWhere ("+ origin + ")")
        const body =  encodeURIComponent(
          `I'm using JustWhere to maintain my addresses. It will be great if you share your address on JustWhere (${origin}).`);
        var link = isMobile() ? "mailto:"+ to + "?subject=" + subject + "&body=" + body :
          "https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=" + to + "&su=" + subject + "&body=" + body;      
          const a = document.createElement("a"); a.setAttribute('href', link); a.setAttribute('target', '_blank'); a.click(); 
        };
        
        return (

    <div className='px-4 py-3 max-w-screen-xl mx-auto items-center'>
      <div className='md:flex md:flex-row items-center justify-between sm:flex sm:flex-col sm:gap-3 sm:mb-3'>
      <div className='flex gap-2 mb-2 mt-2'>
       
        Contacts {contacts?.length || 0}
       

      </div>
      
      <div className='relative sm:order-2'>
       
        <input type="search" id="search" onChange={handleInputChange} class="block w-full form-input" style={{"margin-bottom": "0", "padding-top":"0.5rem"}} placeholder="Search Contacts"  />
        <span class="text-gray-500 absolute end-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1" style={{ bottom: ".3rem" }}><AiOutlineSearch className="inline-icon" /> </span>
       
      </div>
      </div>
     
      
      <div className='p-5 border border-gray-700 mt-2'>
      <ul className='w-full divide-y divide-gray-700'>
        {
          pageState === 0? <>Loading</> :
          pageState === 1? 
          contacts?.length > 0 ? 
          contacts?.filter((c) => c.email.toLowerCase().includes(search.toLowerCase()) || c.name.toLowerCase().includes(search.toLowerCase())  ).map((contactDetail)=>(
            <li className='py-2 md:flex md:flex-row justify-between md:gap-2 sm:flex sm:flex-col sm:gap-3'>
              <div className='flex flex-row gap-2'>
              <div class="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 sm:order-1">
                <svg class="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                </div>
                <div className='flex flex-col gap-2 sm:order-2'>
                  <div className='flex flex-row gap-4 items-center'>
                  <p>{contactDetail.name}</p>
                  {justWhereUsers?.findIndex((jwUser) => contactDetail?.email.toLowerCase() === jwUser.toLowerCase()) > -1 ? <span className='text-xs font-medium me-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400'>JustWhere User</span> : <></>}
                  </div>
                  
                  <p>{contactDetail.email}</p>
                 
                </div>
              </div>
              <div>
                {
                justWhereUsers?.findIndex((jwUser) => contactDetail?.email.toLowerCase() === jwUser.toLowerCase()) > -1 ? <></> : <button onClick={() => sendInviteEmail(contactDetail.email)} className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline md:items-end sm:mt-2">
                  <AiOutlineMail className="inline-icon"/>
                  Invite
                  </button>
                  }
                  </div>
              </li>

          )) : <>No contacts found for this Google Account.</>
          : <>Error loading Google contacts. Please try refreshing the page.</>
        }
        
      </ul>

      </div>
      

    </div>
  )
}

export default Contact