
export default function DeleteModal({confirmationText, deleteLabel, onDeleteFn, onCancelFn, show}){ 
const deleteFn = () => {
    onDeleteFn();
}    
const cancelFn = () => {
    onCancelFn();
}

return show === true ?
<div id="deleteModal" className="fixed flex z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
    <div class="flex-1 p-4 w-full max-w-md h-full md:h-auto">
        <div class=" p-4 text-center rounded-lg shadow bg-gray-800 sm:p-5">
            <p class="mb-4 text-gray-300">{confirmationText}</p>
            <div class="flex justify-center items-center space-x-4">
                <button onClick={deleteFn} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-red-300 bg-red-500 hover:bg-red-600 dark:focus:ring-red-900">
                    {deleteLabel}
                </button>
                <button onClick={cancelFn}type="button" class="py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none hover:text-gray-900 focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
: <></>
}