import axios from 'axios';

const FetchContacts = async (accessToken, pagetoken) => {
  try {
	//MAK: this will need pagination....100 contacts first OR may need a search
	const response = await axios.get('https://people.googleapis.com/v1/people/me/connections', {
      params: {
        resourceName: 'people/me',
        personFields: 'names,emailAddresses',
        access_token: `${accessToken}`,
        //MAK pageSize: 100,
        pageToken: pagetoken,
      },
    });

    // Process the response and extract email contacts
    
    const nextPageToken = response.data.nextPageToken;
    if(response.data.connections == null) return {contacts: [], pagetoken: nextPageToken}
    const contacts = response.data.connections.map((contact) => ({
      name: contact.names ? contact.names[0].displayName : '',
      email: contact.emailAddresses ? contact.emailAddresses[0].value : '',
    }))
    .filter((contact) => contact.email !== '');
    return { contacts: contacts, pagetoken: nextPageToken } ;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

const SearchContacts = async (accessToken, query) => {
  try {
	const response = await axios.get('https://people.googleapis.com/v1/people:searchContacts', {
      params: {
    	query: query,
        readMask: 'names,emailAddresses',
        access_token: `${accessToken}`,
        pageSize: 100,
      },
    });

    // Process the response and extract email contacts
    
    const persons = response?.data?.results || [];
        
    const contacts = persons.map((contact) => ({
      name: contact.person.names ? contact.person.names[0].displayName : '',
      email: contact.person.emailAddresses ? contact.person.emailAddresses[0].value : '',
    }))
    .filter((contact) => contact.email !== '');
    
    console.log(contacts);
    
    return { contacts: contacts } ;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

const GetAllContactsWithEmailAddresses = async(accessToken) =>  {
  let contacts =[], pageToken = "";
  try { 
  let response =  await FetchContacts(accessToken, pageToken);
  contacts = [...contacts, ...response.contacts];
  while(response.pagetoken != null){
    response = await FetchContacts(accessToken, response.pagetoken);
    contacts = [...contacts, ...response.contacts];
  }
  } catch(error) {
    console.log('Error fetching all contacts with email addresses', error);
  }
  return contacts;
}


export {SearchContacts, FetchContacts, GetAllContactsWithEmailAddresses};
