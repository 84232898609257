import React, {  useEffect, useState } from "react";


export default function DateTime(){ 
    const [currDate, setCurrDate] = useState();

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrDate(new Date());
        }, 3600);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return <span className="text-right align-top text-gray-200 text-xs">
              {new Intl.DateTimeFormat(navigator.languages, {
                dateStyle: "short",
                timeStyle: "short",
              }).format(currDate)}
    </span> 

} 