import React, { useEffect, useState } from 'react';
import ReportCard from './ReportCard';

const SecurityReports = () => {
  const[securityReports,setSecurityReports]=useState([]);
  useEffect(()=>{
    fetch(`/securityReports.md`)
    .then((response)=>response.text())
    .then((text)=>{
      const jsonMatch = text.match(/```json([\s\S]*?)```/);
      if(jsonMatch && jsonMatch[1]){
        const jsonString=jsonMatch[1].trim()
        const reports=JSON.parse(jsonString);
        setSecurityReports(reports)
      }
    })
    .catch((error) => console.error('Error fetching Security Reports:', error));
  },[])
  return(
    <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
      <h1 className="bold-text-input">Security Reports</h1>
      <div className='flex flex-wrap justify-center'>
        {
          securityReports?.map((report)=>{
            return(
              <ReportCard report={report}/>
            )
          })
        }

      </div>

    </div>
  )
}

export default SecurityReports