import { BsClipboard2 } from "react-icons/bs";
import { BsClipboard2Check } from "react-icons/bs";
import { useLayoutEffect, useState } from "react";

export default function CopyToClipboard({elId}) {
    const [copied, setCopied] = useState();

    useLayoutEffect(() => { setCopied(false);}, []);

    const copy = () => {
       
        const text =  document.getElementById(elId)?.innerHTML || '';
        navigator.clipboard.writeText(text);
        setCopied(true)
        const timer = setTimeout(() => {
            setCopied(false);
          }, 4000);
    }

    return <button onClick={copy} type="submit" className="pl-1 hover:bg-555"> { !copied ? <BsClipboard2 className="inline-icon" title="Copy to clipboard" /> : <BsClipboard2Check fill="green" className="inline-icon" title="Copied to clipboard"/>}</button> 
    
}