import React,{useState,useEffect} from 'react';
import {Link,useLocation} from "react-router-dom";
import {CiBellOn} from "react-icons/ci";
import {getInfo,GetAccount,GetAccounts,GetNotifications} from "../utilities/calls"

const NotificationIcon = ({notificationCount,link}) => {
  const [loginStatus, setLoginStatus] = useState(localStorage.getItem(document.location.hostname + '.loginStatus'));
  const [view, setView] = useState(localStorage.getItem(document.location.hostname + '.view'));
  const { pathname } = useLocation();
  const [countOfIndividual, setCountOfIndividual] = useState(localStorage.getItem(document.location.hostname + '.individualNotificationsCount') ? localStorage.getItem(document.location.hostname + '.individualNotificationsCount') : 0)
  const [countOfServiceProvider, setCountOfServiceProvider] = useState(localStorage.getItem(document.location.hostname + '.serviceProviderNotificationCount') ? localStorage.getItem(document.location.hostname + '.serviceProviderNotificationCount') : 0)
  const [serviceProviderAccount, setServiceProviderAccount] = useState([])
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [accounts, setAccounts] = useState([]);
  const[beneficiaryAccount,setBeneficiaryAccount]=useState([])
  const[countofBeneficiary,setCountOfBenefiicary]=useState(localStorage.getItem(document.location.hostname + '.beneficiaryNotificationsCount') ? localStorage.getItem(document.location.hostname + '.beneficiaryNotificationsCount') : 0)
  const[combineSPBenNotificationsCount,setCombineSPBenNotificationsCount]=useState()
  const[countOfUser,setCountOfUser]=useState(localStorage.getItem(document.location.hostname + '.userNotificationsCount') ? localStorage.getItem(document.location.hostname + '.userNotificationsCount') : 0)
  const[combineIndUserNotificationsCount,setCombineIndUserNotificationsCount]=useState()
  const freq = process.env.REACT_APP_NOTIFICATION_FREQUENCY;

  
 const SetIndividualNotificationsCount = () => {
    const individualid = getInfo("IndividualID");
    if (individualid == null) return;
    const entityType = "individual"
    if(view === 'S') return;
    GetNotifications(entityType, individualid).then((notificationsData) => {
      let newCount = 0;
      notificationsData.forEach((notification) => {
        if (notification.Status === "new") {
          newCount++
        }
      })
      localStorage.setItem(document.location.hostname + ".individualNotificationsCount", newCount);
    }).catch((error) => {
      setShowAlert(true);
      setAlertText("Error fetching data from server.Please try again");
    });
  };
  const SetUserNotificationsCount=()=>{
    const userId = getInfo("UserID");
        if (userId === null) return;
        const entityType = "user";
        if(view === 'S') return;
        GetNotifications(entityType, userId).then((notificationsData) => {
          let newCount = 0;
          notificationsData.forEach((notification) => {
            if (notification.Status === "new") {
              newCount++
            }
          })
          localStorage.setItem(document.location.hostname + ".userNotificationsCount", newCount);
        }).catch((error) => {
          setShowAlert(true);
          setAlertText("Error fetching data from server.Please try again");
        });
  };

  useEffect(() => {
    let timer;
    SetIndividualNotificationsCount()
    SetUserNotificationsCount()
    const startIndividualPolling = () => {
      timer = setInterval(() => {
          SetIndividualNotificationsCount()
          SetUserNotificationsCount()
          const isLoggedInNow = localStorage.getItem(document.location.hostname + '.loginStatus')
          
          if(isLoggedInNow !== "LoggedIn"){
            clearInterval(timer);
          }
        }, freq); 

      return () => clearInterval(timer); 
    };

    if (loginStatus === "LoggedIn" ) {
      startIndividualPolling();
    }
    return () => clearInterval(timer);
  }, [loginStatus, view]);

  const fetchAccounts=()=>{
    const individualid = getInfo("IndividualID");
    if (individualid === null) return;
    GetAccounts().then((response) => {
      console.log('GETAccounts response', response)
      const accountPromises=Object.keys(response).map((key)=>{
        return GetAccount(key).catch((error) => {
          console.log(`Error in Getting Account details for ID ${key}`, error);
          return null
        });
      });
      Promise.all(accountPromises).then((accounts)=>{
        const validAccounts=accounts.filter((account) => account !== null)
        setAccounts(validAccounts)
      }).catch((error)=>{
        console.log("Error Getting account Details ",error);
        setShowAlert(true);
        setAlertText("Error fetching data from server. Please try again!");
      })
    }).catch((error) => {
      console.error("Error fetching accounts:", error);
      setShowAlert(true);
      setAlertText("Error fetching data from server. Please try again!");
    });
  }
  
  useEffect(() => {
    if(loginStatus === "LoggedIn" && view === "S") fetchAccounts();
  },[loginStatus,view])
  useEffect(() => {
    let timer;

    const startSPPolling = () => {
      SetServiceProviderNotificationsCount()
      SetBeneficiaryNotificationsCount();
      timer = setInterval(() => {
          const isLoggedInNow = localStorage.getItem(document.location.hostname + '.loginStatus')
          SetServiceProviderNotificationsCount()
          SetBeneficiaryNotificationsCount();
          if(isLoggedInNow !== "LoggedIn" ){
            clearInterval(timer);
          }
        }, freq); 

      return () => clearInterval(timer); 
    };

    if (loginStatus === "LoggedIn") {
      startSPPolling();
    }
    return () => clearInterval(timer);
  }, [loginStatus, view,serviceProviderAccount,beneficiaryAccount]);
  
  useEffect(() => {
    const newServiceProviderAccounts = [];
    const newBeneficiaryAccounts = [];
    accounts.forEach((account) => {
        newServiceProviderAccounts.push(account?.ServiceProviders);
        newBeneficiaryAccounts.push(account?.Beneficiaries);
      });
      setServiceProviderAccount(newServiceProviderAccounts);
      setBeneficiaryAccount(newBeneficiaryAccounts);
  }, [accounts]);
  
  const SetServiceProviderNotificationsCount = () => {
    if(view !== "S") return;
      let newCount = 0;
      const entityType = "serviceprovider"
      if(serviceProviderAccount !== undefined && serviceProviderAccount !== null){
        serviceProviderAccount.forEach((sp)=>{
          const notificationPromises=Object.keys(sp).map((serviceProviderId)=>{
            return GetNotifications(entityType, serviceProviderId).catch((error)=>{
              console.log(`Error in fetching Notifications for Service Provider of ID ${serviceProviderId}`)
              return null
            });
          });
          Promise.all(notificationPromises).then((notifications)=>{
            const validNotifications=notifications.filter((notifications)=>notifications !== null)
            validNotifications.forEach((notificationArr)=>{
              notificationArr.forEach((notification)=>{
                if(notification.Status === "new"){
                  newCount=newCount+1
                }
              })
            })
            localStorage.setItem(document.location.hostname + ".serviceProviderNotificationCount", newCount);
          }).catch((error)=>{
            console.log("Error in Getting SP Notifications",error);
            setShowAlert(true);
            setAlertText("Error fetching data from server.Please try again");
          })
          })
    }
  }
    const SetBeneficiaryNotificationsCount=()=>{
      if(view !== "S") return;

      let newCount = 0;
      const entityType = "beneficiary"
      if(beneficiaryAccount !== undefined && beneficiaryAccount !== null){
        beneficiaryAccount.forEach((ben)=>{
          const notificationPromises=Object.keys(ben).map((benId)=>{
            return GetNotifications(entityType, benId).catch((error)=>{
              console.log(`Error in fetching Notifications for Benificiary of ID ${benId}`)
              return null
            })
          })
          Promise.all(notificationPromises).then((notifications)=>{
            const validNotifications=notifications.filter((notifications)=>notifications !== null)
            validNotifications.forEach((notificationArr)=>{
              notificationArr.forEach((notification)=>{
                if(notification.Status === "new"){
                  newCount=newCount+1
                }
              })
            })
            localStorage.setItem(document.location.hostname + ".beneficiaryNotificationsCount", newCount);
          })
          .catch((error) => {
            console.log("Error in Getting Ben Notifications",error);
            setShowAlert(true);
            setAlertText("Error fetching data from server.Please try again");
          });
        })
      }
    }
    
useEffect(() => {
  setLoginStatus(localStorage.getItem(document.location.hostname + '.loginStatus'));
  setView(localStorage.getItem(document.location.hostname + '.view'))
});
useEffect(() => {
  setCountOfIndividual(localStorage.getItem(document.location.hostname + '.individualNotificationsCount'))
  setCountOfServiceProvider(localStorage.getItem(document.location.hostname + '.serviceProviderNotificationCount'))
  setCountOfBenefiicary(localStorage.getItem(document.location.hostname + '.beneficiaryNotificationsCount'))
  setCountOfUser(localStorage.getItem(document.location.hostname + '.userNotificationsCount'))
  const timer = setInterval(() => {
    setCountOfIndividual(localStorage.getItem(document.location.hostname + '.individualNotificationsCount'))
    setCountOfServiceProvider(localStorage.getItem(document.location.hostname + '.serviceProviderNotificationCount'))
    setCountOfBenefiicary(localStorage.getItem(document.location.hostname + '.beneficiaryNotificationsCount'))
    setCountOfUser(localStorage.getItem(document.location.hostname + '.userNotificationsCount'))
    }, 300);
  return () => {
    clearInterval(timer)
  }

}, [])
useEffect(()=>{
  const totalNotifications = Number(countOfServiceProvider) + Number(countofBeneficiary);
  setCombineSPBenNotificationsCount(Number(totalNotifications))
},[countOfServiceProvider,countofBeneficiary])
useEffect(()=>{
  const totalNotifications = Number(countOfIndividual) + Number(countOfUser);
  setCombineIndUserNotificationsCount(Number(totalNotifications))
},[countOfIndividual,countOfUser])
  return (
    <>
    {
                  loginStatus === "LoggedIn" && view === "S" ?
          <Link to="/serviceprovidernotifications" className="flex gap-2 relative" style={{
            "background-color": pathname === "/serviceprovidernotifications" ? '#1f2937' : "",
            "borderRadius": pathname === "/serviceprovidernotifications" ? '50%' : '',
            "padding": pathname === "/serviceprovidernotifications" ? '4px' : ''
          }}>
            {
              combineSPBenNotificationsCount > 0 && <span className="notification-count h-6 w-6 text-center bg-red-700">{combineSPBenNotificationsCount}</span>
            }

            <button className={`${combineSPBenNotificationsCount > 0 ? "notification-bell" : ""}`}>

              <CiBellOn size={30} fill="#fac82a" />

            </button>

          </Link>

          : loginStatus === "LoggedIn" &&


          <Link to="/individualnotifications" className="flex gap-2 relative" style={{
            "background-color": pathname === "/individualnotifications" ? '#1f2937' : "",
            "borderRadius": pathname === "/individualnotifications" ? '50%' : '',
            "padding": pathname === "/individualnotifications" ? '4px' : ''
          }}>
            {
              combineIndUserNotificationsCount > 0 && <span className="notification-count h-6 w-6 text-center bg-red-700">{combineIndUserNotificationsCount}</span>
            }
            <button className={`${combineIndUserNotificationsCount > 0 ? "notification-bell" : ""}`}>

              <CiBellOn size={30} fill="#fac82a" />

            </button>

          </Link>
      }

    
    </>
    
  )
}

export default NotificationIcon