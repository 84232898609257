import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { renderField, renderTag } from "../utilities/functions";

export default function CreateCustomTag({ isReadOnly, saveFn, savedTags, deleteFn }) {

    const [showModal, setShowModal] = useState();
    const [tags, setTags] = useState(savedTags);
    const [name, setName] = useState();
    const [value, setValue] = useState();
    const [privateVal, setPrivateVal] = useState();

    useEffect(() => {
        console.log(savedTags)
        setTags(Object.values(savedTags)); setShowModal(false);
        console.log(tags)
    }, [savedTags]);


    const openCustomTagModal = () => {
        setShowModal(true);
    }

    const saveCustomTag = () => {
        console.log(privateVal)
        const tag = { Name: name, Value: value, Required: false, Editable: true, Secure: false, Private: privateVal }
        setTags((tags) => [...tags, tag]);
        saveFn(tag);
        setShowModal(false);
        setName(""); setValue(""); setPrivateVal(false);
    }

    const handleTagDelete = (e) => {
        const button = e.target.closest("button");
        const name = button.name;
        console.log('handleTagDelete', name, button.name, tags);
        deleteFn(name);
        setTags((tags) => tags.filter((tag) => { return tag.Name !== name }))
    }

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "privateVal") setPrivateVal(checked);
        else if (name === "name") setName(value);
        else setValue(value);
    }
    const cancelFn = () => {
        setShowModal(false)
    }
    return <>
        {(tags).map((element) => {
            return renderTag(element.Name, element.Name, element.Value, element.Private, handleInputChange, handleTagDelete);
        })
        }
        {isReadOnly ? <></> :
            showModal ? <div id="customTagModal">
                {renderField("Name", "name", "alphanumeric", name, handleInputChange)}
                {renderField("Value", "value", "alphanumeric", value, handleInputChange)}
                {renderField("Private (Only visible to you.)", "privateVal", "checkbox", privateVal, handleInputChange)}
                <button onClick={saveCustomTag} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 bg-blue-500 hover:bg-blue-600 dark:focus:ring-blue-900 gap-3" style={{ "margin-right": "1em" }}>
                    Add
                </button>
                <button onClick={cancelFn} type="button" class="py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none hover:text-gray-900 focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600">
                    Cancel
                </button>
            </div>
                : <button onClick={openCustomTagModal} type="submit" className="button-style px-1 hover:bg-555"><BsPlus className="inline-icon" /> Add More </button>

        }</>
}