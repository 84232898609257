import React, { useEffect, useState } from "react";
import { FetchContacts, SearchContacts } from "../utilities/email";
import { getInfo } from "../utilities/calls";
import { Link } from "react-router-dom";
import EllipsisText from "./EllipsisText";
import "./EMailList.css";

const EMailList = () => {
  const [contacts, setContacts] = useState({});
  const [set, setSet] = useState(false);
  const [pagetoken, setPageToken] = useState("");
  const [next, setNext] = useState(false);
  const [search, setSearch] = useState("");
  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    const gmailaccesstoken = getInfo("Gmailaccesstoken");
    if (gmailaccesstoken) {
    	
		SearchContacts(gmailaccesstoken, "").then((response) => {
//			console.log(response.contacts)
		});
		
		if (search) {
			SearchContacts(gmailaccesstoken, search).then((response) => {
				console.log(response.contacts)
				console.log(response.contacts.length)
				setContacts(response.contacts);
				setSet(true);
			});
		} else {
	    	FetchContacts(gmailaccesstoken, pagetoken).then((response) => {
				console.log(response);
				setContacts(response.contacts);
				setPageToken(response.pagetoken);
				setSet(true);
			})
			.catch((err) =>{
				console.log(err);
			});
		};
    };
  }, [next,search]);

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div>
        <h1>GMail Contact to Choose From</h1>
      </div>
      <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <section className="gap-20">
          <ul style={{ display: "flex", listStyleType: "none", padding: 0 }}>
            {!set ? (
              <li key="save" style={{ marginLeft: "20px" }}>
				  <a id="getcontacts" href="/api/gmail/authorization/call">
					<button className="button-style">GET CONTACTS</button>
				  </a>
              </li>
            ) : null}
            {
              <li key="next" style={{ marginLeft: "20px" }}>
            		<input disabled={!set} className="button-style disableButton"
	        			type="text" value={search}
	        			onChange={handleInputChange}
	        			placeholder="search names starting with..." />
					<button disabled={!set} onClick={() => setNext(!next)} className="button-style disableButton">Next...</button>
	                <Link to="/requests">
	                  <button style={{ marginLeft: "20px" }} className="button-style">Close</button>
	                </Link>
              </li>
            }
          </ul>
        </section>
      </div>
   	  <div>
		<section>
          <h1 className="bold-text-input px-5">
                Contacts from GMail {contacts?.length || 0}
          </h1>
          <table className="border-collapse table-fixed">
              <thead>
                <tr className="">
                  <th className="px-5 text-left border-b w-1by5"> Name </th>
                  <th className="px-5 text-left border-b w-1by10"> Email </th>
                </tr>
              </thead>
              <tbody>
            	{Object.keys(contacts).map((id) => {
                  return (
                    <tr className="p-5">
                      <td className="p-5 text-left w-1by5">
                        <EllipsisText
                          text={contacts[id].name}
                          maxLength={25}
                        ></EllipsisText>
                      </td>
                      <td className="p-5 text-left w-1by5">
                        <EllipsisText
                          text={contacts[id].email}
                          maxLength={25}
                        ></EllipsisText>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
        </section>
      </div>
    </div>
  );
};

export default EMailList;
