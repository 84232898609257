import { useNavigate,Link } from "react-router-dom";
import {  AiOutlineInfoCircle, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsFillTrash3Fill} from "react-icons/bs";
import { displayDate } from "../utilities/functions";


export default function AddressBox({ address, onDelete, ben, message, onConnect, sharedTo, benList, connected,serviceProviderId,benId, sentBadge, from, to, created, updated }) {
    const navigate=useNavigate()
    const fetchServiceProviderDetails=(serviceProviderID)=>{
      if(serviceProviderID !== null && serviceProviderID !== undefined){
          navigate(`/AddServiceProviderProfile/${serviceProviderID}`,{ state: { readOnly:true,showPrivate:false,previousPage: "/sharing" } })
        }
    }
    const fetchBenDetails=(benId)=>{
      if(benId !== null && benId !== undefined){
        navigate(`/AddBeneficiaryProfile/${benId}`,{ state: { readOnly:true,showPrivate:false,previousPage: "/sharing" } })
      }
    }
    return (
      address ?
        <div className="md-container border border-gray-500 bg-gray-800 rounded m-1 py-6 px-3" style={{ "overflow-wrap": "break-word" }}>
          <div className="flex flex-row justify-between">
            <p className="font-bold leading-6 text-white flex flex-row justify-around">
              {address?.tags?.atag?.Name}
              </p>
            <p>
              {onDelete ?
                <button formMethod="dialog" formTarget="top" id="deleterequest" className="bg-slate-333 py-1 hover:bg-555" onClick={onDelete}>
                  <BsFillTrash3Fill title="Delete" className="inline-icon" />
                </button>
                : <></>}
            </p>
          </div>
          <p>{        sentBadge      ?                <span class="text-xs text-center px-1 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400">Address Sent</span>:<></>}
            </p>
          <p className="mt-1 text-xs text-gray-400">{ updated ? "Updated " + displayDate(updated) : ""}  </p>  
          {updated && created && updated !== created ? <p className="text-xs text-gray-400">{created ? "Created " + displayDate(created) : ""}</p>: <></>}
          <p className="mt-2 truncate text-sm leading-5 text-gray-100">
            {address?.addressee}
          </p>
          <p className="mt-1 truncate text-sm leading-5 text-gray-100">
           {address?.street}
          </p>
          <p className="mt-1 truncate text-sm leading-5 text-gray-100">
            {address?.city ? address?.city +"," : <></>} {address?.state} {address?.zipcode}
          </p>
          <p className="mt-1 truncate text-sm leading-5 text-gray-100">
            {address?.country}
          </p>
          { address?.phone ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon"/>{address?.phone}</p> : <></>}
          {address?.email ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon"/>{address?.email}</p> : <></> }
          {from && from > 0 ?
            <p className="mt-2 leading-6 text-gray-100">
              <span className="text-xs ">Shared By: </span>
              <span className="text-xs  text-blue-400">{from}</span>
            </p> : <></>
          }
          {to && to.length > 0 ?
            <p className=" leading-6 text-gray-100">
              <span className="text-xs">Shared With: </span>
              <span className="text-xs text-blue-400">{to}</span>
            </p> : <></>
          }
          {sharedTo && sharedTo.length > 0 ?
            <p className=" leading-6 text-gray-100">
              <span className="text-xs">Shared With: </span>
              {
                ben ? (<button className="text-xs text-blue-400" onClick={()=>fetchServiceProviderDetails(serviceProviderId)}>{sharedTo}</button>)
                : (<button className="text-xs text-blue-400" onClick={()=>fetchBenDetails(benId)}>{sharedTo}</button>)
              }
              </p> : <></>
          }
  
          {
            ben ?
              ben.map((id) => {
                
                return <div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
                  <div class="flex">
                    { connected ? <div class="py-2 text-sm "> <span className="font-semibold ">Great! </span><div>You are connected with {benList[id]?.tags?.name?.Value + " (beneficiary)"} recommended by {sharedTo + " (Service Provider)"}.</div></div>
                    :
                    <div class="ms-1 text-sm font-normal">
                      <span class="mb-1 text-sm font-semibold text-white"><AiOutlineInfoCircle className="inline-icon"/> Recommendation! </span>
                      <div class="py-2 text-xs">{sharedTo} has referred {benList[id]?.tags?.name?.Value} to you.</div>
                      <blockquote class="text-xs italic font-semibold text-white">
                        <p>{ message && message.length > 0 ? '"' + message + '"' : <></>}</p>
                      </blockquote>
                      <div class="grid grid-cols-2 gap-2 p-2">
                        <div>
                          <Link to={`/AddBeneficiaryProfile/${id}`} state={{ readOnly: true, showPrivate: false, previousPage: '/sharing' }} >
                            <button class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">View</button></Link>
                        </div>
                        <div>
                          <button onClick={onConnect} class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">Connect</button>
                        </div>
                      </div>
                    </div>
                  }
                  </div>
                </div>
  
  
              }) : <></>
          }
        </div>
        : <></>);
  }