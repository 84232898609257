import { AiOutlineLogin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getInfo, CreateAccount } from "../utilities/calls"

export default function ServiceProviderLogin({viewStateFn}) {

  const navigate = useNavigate();

  const createServiceProvider = () => {
    localStorage.setItem(document.location.hostname + '.view', 'S');
    viewStateFn('S')

    const individualid = getInfo("IndividualID");
    CreateAccount("", {}, {}, {}).then((response) => { 
      //save the id in cookie
      console.log('create service provider', response )
      navigate('/serviceProviders')

    }).catch((error) => {
      console.error("create service provider: " , error);
    });
  }

  return (
      <div className="mx-auto max-w-screen-xl text-center ">
        <h1 className="mb-4 text-2xl font-bold tracking-tight leading-none text-gray-800">Are you a Service Provider?</h1>
        <h2>Share services details on JustWhere community.</h2>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
          <p className="py-3 flex justify-center">
            <button className=" button-style" id="profile" onClick={createServiceProvider}>
              <AiOutlineLogin className="inline-icon" />
              I am a Service Provider
            </button></p>
        </div>
      </div>
  )
};

