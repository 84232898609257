// src/components/Sharing.js
import React, { useLayoutEffect, useState } from "react";
import { GetAddress, GetShares, GetSharesWith, getInfo, DeleteSecondaryToken, DeletePrimaryToken, GetRequestDetails, CreateSecondaryToken, GetServiceProvider, GetBeneficiary } from "../utilities/calls";
import "./Sharing.css";
import Login from "./Login";
import DeleteModal from "./DeleteModal";
import AddressBox from "./AddressBox";


const IndividualShares = () => {
    const [addresses, setAddresses] = useState([]);
    const [addressesSharedWithBene, setAddressesSharedWithBene] = useState([]);
    const [addressesSharedWithSP, setAddressesSharedWithSP] = useState([]);

    const [shares, setShares] = useState([]);
    const [spShares, setSpShares] = useState([]);
    const [sharesWithBen, setSharesWithBen] = useState([]);
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [currShare, setCurrShare] = useState();
    const [sP, setSP] = useState();
    const [refBens, setRefBens] = useState({});
    const [rNote, setRNote] = useState({});
    const [benList, setBenList] = useState({});
    const [spList, setSpList] = useState({});


    useLayoutEffect(() => {
        setUserId(getInfo("UserID"));
        setShowDeleteDlg(false);
    }, []);

    useLayoutEffect(() => {
        const individualid = getInfo("IndividualID");

        GetSharesWith(individualid)
            .then((data) => {
                setShares(data);
                Promise.all(
                    data.map(async (el) => {
                        try {
                            const benId = el.tags?.RequestId ? "" : el.beneficiaryID
                            const result = await GetAddress(
                                el.addressID,
                                benId,
                                el.token,
                                el.individualID
                            );
                            
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddresses((addresses) => [...addresses, ...addrs]);
                    })
                    .catch((e) => {
                        console.log(e);
                        setShowAlert(true);
                        setAlertText("Error fetching data");
                    });
            })
            .catch((error) => {
                console.error("Error fetching sharedWith:", error);
                setShowAlert(true);
                setAlertText("Error fetching data");
            });
        getSharesWithBene();
    }, []);

    const getSharesWithBene = () => {
        const individualid = getInfo("IndividualID");
        const ownertoken = getInfo("Ownertoken")
        GetShares(individualid)
            .then((data) => {
                
                let ben = data.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0);
                setSharesWithBen(ben);
                
                ben.map((b) => {
                        if(b.serviceProviderID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0)
                            GetBeneficiary(b.beneficiaryID).then((el) => setBenList((benList) => ({...benList, [b.beneficiaryID] : el}))).catch((err) => console.log(err))

                    })

                Promise.all(
                    ben.map(async (el) => {
                        try {
                            const result = await GetAddress(
                                el.addressID,
                                "",
                                ownertoken,
                                el.individualID
                            );
                           
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddressesSharedWithBene((addressesSharedWithBene) => [
                            ...addressesSharedWithBene,
                            ...addrs,
                        ]);
                    })
                    .catch((e) => {
                        console.log(e);
                    });

                
                    
                let sp = data.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") === 0);
                setSpShares(spShares => [...spShares, ...sp]);

                Promise.all(
                    sp.map(async (el) => {

                        try {
                            const result = await GetAddress(
                                el.addressID,
                                "",
                                ownertoken,
                                el.individualID
                            );
                           
                            return result;
                        } catch (err) {
                            console.error("Error fetching addresses:", err);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                        }
                    })
                )
                    .then((addrs) => {
                        setAddressesSharedWithSP((addressesSharedWithSP) => [
                            ...addressesSharedWithSP,
                            ...addrs,
                        ]);
                    })
                    .catch((e) => {
                        console.log(e);
                    });

                sp.map((s) => {
                    try {
                       
                        GetServiceProvider(s.serviceProviderID).then((el) => setSpList((spList) => ({...spList, [s.serviceProviderID] : el}))).catch((err) => console.log(err))


                        return GetRequestDetails(s.tags?.RequestId?.Value).then((r) => {
                            const refs = r.tags?.beneficiaryReference?.Value || [];
                            setRNote((rNote) => ({...rNote, [s?.tags?.RequestId?.Value] : r.tags?.note?.Value || ""}))
                            setRefBens((refBens) => ({ ...refBens, [s?.tags?.RequestId?.Value]: refs }));
                        }).catch((err) =>{
                            console.log(err);
                        });

                    } catch (err) {
                        console.error("Error fetching request details:", err);
                        setShowAlert(true);
                        setAlertText("Error fetching data");
                    }
                })
            })
            .catch((error) => {
                console.error("Error fetching shares:", error);
                setShowAlert(true);
                setAlertText("Error fetching data");
            });
    }

    const deleteServiceProviderShare = () => {
        setShowDeleteDlg(false);
        console.log('deleteSPShare')

        const shareReq = currShare

        DeletePrimaryToken(shareReq.serviceProviderID, shareReq.individualID, shareReq.addressID)
            .then((response) => {
                setShowAlert(true);
                setAlertText("Removed share successfully.");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithSP([])
                setAddressesSharedWithBene([])
                setSharesWithBen([]);
                setSpShares([]);
                getSharesWithBene();
            })
            .catch((error) => {
                console.error("Error removing share", error);
                setShowAlert(true);
                setAlertText("Error removing a share.");
            });
    }

    const deleteShare = () => {
        setShowDeleteDlg(false);
        if (sP) { deleteServiceProviderShare(); return; }
        const shareReq = currShare;
        var addressOffered = !shareReq.tags?.RequestId 
        let id = shareReq.serviceProviderID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? (addressOffered ?  shareReq.tags.SharedWithEmail.Value : "")  : shareReq.beneficiaryID;
        
        DeleteSecondaryToken("", id, shareReq.token)
            .then((response) => {
                setShowAlert(true);
                setAlertText("Removed share successfully.");
                const timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                setAddressesSharedWithBene([])
                setAddressesSharedWithSP([])
                setSharesWithBen([]);
                setSpShares([]);
                getSharesWithBene();
            })
            .catch((error) => {
                console.error("Error removing share", error);
                setShowAlert(true);
                setAlertText("Error removing a share.");
                
            });
    }
    const handleDeleteClick = (share, sp) => {
        console.log(addressesSharedWithBene, shares, sp)
        setConfirmationText("Are you certain you wish to Remove the sharing of this address?")
        setShowDeleteDlg(true);
        setCurrShare(share);
        setSP(sp)
    }


    const connect = (sp, address) => {
        console.log('connect ', sp , address);
        const shareReq = spShares.find((el) => el.addressID === address.id && sp.serviceProviderID === el.serviceProviderID);
        CreateSecondaryToken(shareReq.serviceProviderID, refBens[sp?.tags?.RequestId?.Value][0] , shareReq.token).then((resp) => { 
            setShowAlert(true);
            setAlertText("You have connected with the recommended beneficiary! ");
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            setAddressesSharedWithBene([])
            setAddressesSharedWithSP([])
            setSpShares([])
            getSharesWithBene();
        }).catch((err) => {console.log('error creating secondary token', err)})
    }
    
    return userId ? (

        <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
            <DeleteModal confirmationText={confirmationText} deleteLabel="Remove Share" onDeleteFn={deleteShare} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />

            <h1 className="bold-text-input">Address Shares</h1>
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >
                <span class="text-sm">{alertText} </span>
            </div>

            <div>
                <h1 className="p-5 bold-text-input">Addresses Shared with me</h1>
                <div class=" bg-gray  ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                shares && shares!== undefined && shares.length > 0 ?
                                shares.map((share) => {
                                    var address = addresses.find((ele) => share.addressID === ele.id)
                                    var from = decodeURIComponent(
                                        share?.tags?.UserEmail ? share.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        share?.tags?.SharedWithEmail ? share.tags.SharedWithEmail.Value : ""
                                    );
                                    var addressOffered = !share.tags?.RequestId 
                                    return <AddressBox created={share?.created ? share.created : ""} updated={share?.updated ? share.updated : ""} address={address} from={from} to={to} sentBadge={addressOffered} />

                                }) : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Service Provider</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                spShares.map((sp) => {
                                    var address = addressesSharedWithSP.find((ele) => sp.addressID === ele.id)
                                    var bn = refBens[sp?.tags?.RequestId?.Value] || [];
                                    var connected = sharesWithBen.find((el)=> el.serviceProviderID === sp.serviceProviderID && el.addressID === sp.addressID)
                                    var serviceProviderID=sp.serviceProviderID
                                    var from = decodeURIComponent(
                                        sp?.tags?.UserEmail ? sp.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        sp?.tags?.SharedWithEmail ? sp.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <AddressBox created={sp?.created ? sp.created : ""} updated={sp?.updated ? sp.updated : ""}  from={from} to={to} benList={benList} connected={connected} sharedTo={spList[sp.serviceProviderID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(sp, true)} ben={bn} message={rNote[sp?.tags?.RequestId?.Value]} onConnect={() => connect(sp, address)} serviceProviderId={serviceProviderID}></AddressBox>
                                        
                                       
                                    </>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="p-5 bold-text-input">Shared with Beneficiary</h1>
                <div class=" bg-gray ">
                    <div class=" max-w-4xl ">
                        <div class="grid grid-flow-row px-3 gap-4 grid-cols-1 md:grid-cols-3">
                            {
                                sharesWithBen.map((ben) => {
                                    var address = addressesSharedWithBene.find((ele) => ben.addressID === ele.id)
                                    var benId=ben.beneficiaryID
                                    var from = decodeURIComponent(
                                        ben?.tags?.UserEmail ? ben.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        ben?.tags?.SharedWithEmail ? ben.tags.SharedWithEmail.Value : ""
                                    );
                                    return <>
                                        <AddressBox created={ben?.created ? ben.created : ""}  updated={ben?.updated ? ben.updated : ""} from={from} to={to} sharedTo={benList[ben.beneficiaryID]?.tags?.name?.Value} address={address} onDelete={() => handleDeleteClick(ben, false)} benId={benId} ></AddressBox>
                                        
                                       
                                    </>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    ) : (
        <Login />
    );
};

export default IndividualShares;
