import React,{useState,useRef} from 'react';
import { pdfjs,Document, Page  } from 'react-pdf';
import { IoIosCloseCircle } from "react-icons/io";
import useResizeObserver from '@react-hook/resize-observer';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ReportCard = ({report}) => {
    const [securityReport,setSecurityReport] = useState(null);
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const ref = useRef(null);
  useResizeObserver(ref, (entry) => {
    setDimensions({
      width: entry.contentRect.width,
      height: entry.contentRect.height,
    });
  });
    function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
   const handleViewPdf = async()=>{
    try {
      const response = await fetch(`${report.reportUrl}`);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setSecurityReport(url);
    } catch (error) {
      console.error('Error fetching the PDF file:', error);
    }
      }
      const handleDownloadPdf = async()=>{
      const response = await fetch(`${report.reportUrl}`);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      let link=document.createElement("a")
      link.href=url
      link.download=`${report.reportName}`;
      link.click();
      document.body.removeChild(link);
    }
  return (
    <div ref={ref} className="max-w-sm rounded overflow-hidden shadow-lg p-4 m-4 border border-gray-700">
        <button onClick={handleViewPdf}>
        <img
        className="w-full"
        src={`${report.reportImg}`}
        alt="PDF Thumbnail"
      />
        </button>
      
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{`${report.reportName}`}</div>
        </div>
      <div className="px-6 pt-4 pb-2">
        <button
          onClick={handleDownloadPdf}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2"
        >
          Download PDF
        </button>
      </div>
      {securityReport && (
        <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">
            <div className="grid justify-items-stretch w-full max-w-sm bg-white p-2 rounded">
                <button onClick={() => setSecurityReport(null)} className="justify-self-end sticky top-4 z-10 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded mb-4">
                    <IoIosCloseCircle/>
                </button>
                <div className="mx-auto">
                <Document file={securityReport} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => {
                        return (
                        <Page
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="mb-4"
                        width={dimensions.width * 0.9}/>
                    );
                    })}
                    </Document>
                    </div>
                    </div>
                    </div>
                )}
                </div>
                )
}

export default ReportCard