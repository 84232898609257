import { Link } from "react-router-dom";

export default function AboutUs () {
  return (
    <div className="p-2">
      <Link to="/aboutUs"><h2 className="text-white text-3xl font-extrabold mb-2">About Us</h2></Link>

      <p className="p-2">Welcome to JustWhere!

      At JustWhere, we are passionate about providing centralized, secure platform and  streamlined solutions for individual as well as businesses to manage and store data safely, securely.
      </p>
      <p className="px-2">At JustWhere, we are committed to quality, secure, privacy respected services reducing the risk of exposing sensitive data to thirdparty applications. We believe in  and aim to improve efficiency and convenience in various transactions and interactions.
      </p>

      <h1 className="text-xl py-2">Our Team</h1>

      <div className="grid grid-cols-3 gap-4">
        <div className="text-center">
          <img src="./mandar.jpg" alt="Mandar Khadilkar" className="mx-auto rounded-full h-24 w-24 mb-2" />
          <p className="font-bold">Mandar Khadilkar</p>
          <p>Co-founder and CEO</p>
        </div>
        <div className="text-center">
          <img src="./asmita.jpg" alt="Asmita Date" className="mx-auto rounded-full h-24 w-24 mb-2" />
          <p className="font-bold">Asmita Date</p>
          <p>Co-Founding Engineer UI/UX</p>
        </div>
        <div className="text-center">
          <img src="./vivek.jpg" alt="Vivek Bhanuprakash" className="mx-auto rounded-full h-24 w-24 mb-2" />
          <p className="font-bold">Vivek Bhanuprakash</p>
          <p>Technology Advisor</p>
        </div>
        {/* Add more team members here */}
      </div>

      <h1 className="py-2 text-xl">Contact Us</h1>

      <p className="p-2">We would love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to [provide contact information or link to your contact page].</p>

      <p className="p-2">Thank you for choosing JustWhere!</p>
    </div>
  );
}