// src/components/Navigation.js
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navigation.css"; // Import the CSS file
import { SetupInterceptor } from "../utilities/calls";
import {  AiOutlineHome, AiOutlineMessage } from "react-icons/ai";
import { AiOutlineProfile } from "react-icons/ai";
import { AiOutlineShareAlt } from "react-icons/ai";
import { AiOutlineImport } from "react-icons/ai";
import { TiContacts } from "react-icons/ti"

const Navigation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [view, setView] = useState(localStorage.getItem(document.location.hostname + '.view'));


  useLayoutEffect(() => {
    SetupInterceptor(navigate)
  }, [navigate]);

  useEffect(()=>{
    setView(localStorage.getItem(document.location.hostname + '.view'))
  })

  return (
    <nav style={{ "background-color": "#000000", "color": "#fff" }}>
      <div class="max-w-screen-xl px-4 py-3 mx-auto grid grid-cols-1 justify-between items-center">
        {view === "S" ?<>
        <ul class="flex flex-row justify-stretch mt-0  rtl:space-x-reverse text-sm w-full	">
            <li className="w-full"><Link className="flex  justify-center items-center  rounded-t text-sm md:text-base hover:bg-555	hover:text-white " style={{ "background-color": pathname === "/" ? "#333" : "#000" }} to="/" >
              <AiOutlineHome className="inline-icon" />
            </Link></li>
            <li className="w-full "><Link className="flex  justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/spsharing" ? "#333" : "#000" }} to="/spsharing">
              <AiOutlineShareAlt className="inline-icon" />
            </Link></li>
            <li className="w-full "><Link className="flex justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/serviceProviders" ? "#333" : "#000" }} to="/serviceProviders">
              <AiOutlineProfile className="inline-icon" />
            </Link></li>
            <li className="w-full "><Link className="flex  justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/requests" ? "#333" : "#000" }} >
              <AiOutlineMessage className="inline-icon" />
            </Link></li>
            <li className="w-full "><Link className="flex  justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white" style={{ "background-color": pathname === "/contacts" ? "#333" : "#000" }} to="/contacts" >
              <TiContacts className="inline-icon"/>
            </Link></li>

          </ul>
        <ul class="flex flex-row justify-stretch mt-0 rtl:space-x-reverse text-sm w-full	">
        <li className="w-full"><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:underline-offset-1	hover:text-white " style={{ "background-color": pathname === "/" ? "#333" : "#000" }} to="/" >
          <span className="">Home</span>
        </Link></li>
        <li className="w-full "><Link className="flex   justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 	" style={{ "background-color": pathname === "/spsharing" ? "#333" : "#000" }} to="/spsharing">
          <span className="">Shares</span>
        </Link></li>
        <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/serviceProviders" ? "#333" : "#000" }} to="/serviceProviders">
          <span className="">Services</span>
        </Link></li>
        <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/requests" ? "#333" : "#000" }} >
          <span className="">Messages</span>
        </Link></li>
        <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/contacts" ? "#333" : "#000" }} to="/contacts" >
          <span className="">Contacts</span>
        </Link></li>

      </ul>
      </>
          :
          <>
          <ul class="flex flex-row justify-stretch mt-0 rtl:space-x-reverse text-sm w-full	">
          <li className="w-full"><Link className="flex justify-center items-center rounded-t text-sm md:text-base hover:bg-555	hover:text-white " style={{ "background-color": pathname === "/" ? "#333" : "#000" }} to="/" >
            <AiOutlineHome className="inline-icon" />
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/address-list" ? "#333" : "#000" }} to="/address-list">
            <AiOutlineProfile className="inline-icon" />
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/sharing" ? "#333" : "#000" }} to="/sharing">
            <AiOutlineShareAlt className="inline-icon" />
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/requests" ? "#333" : "#000" }} to="/requests">
            <AiOutlineImport className="inline-icon" />
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-t text-sm md:text-base hover:bg-555 hover:text-white " style={{ "background-color": pathname === "/contacts" ? "#333" : "#000" }} to="/contacts" >
              <TiContacts className="inline-icon"/>
            </Link></li>

        </ul>
        <ul class="flex flex-row justify-stretch mt-0  rtl:space-x-reverse text-sm w-full	">
          <li className="w-full"><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:underline-offset-1	hover:text-white" style={{ "background-color": pathname === "/" ? "#333" : "#000" }} to="/" >
            <span className="">Home</span>
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/address-list" ? "#333" : "#000" }} to="/address-list">
            <span className="">Addresses</span>
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/sharing" ? "#333" : "#000" }} to="/sharing">
            <span className="">Shares</span>
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/requests" ? "#333" : "#000" }} to="/requests">
            <span className="">Requests</span>
          </Link></li>
          <li className="w-full "><Link className="flex justify-center items-center rounded-b text-sm md:text-base hover:bg-555 hover:text-white hover:underline-offset-1 " style={{ "background-color": pathname === "/contacts" ? "#333" : "#000" }} to="/contacts" >
              <span className="">Contacts</span>
            </Link></li>

        </ul>
        </>
        }
      </div>
    </nav>

  );
};

export default Navigation;
