import { useState,  useLayoutEffect,useEffect } from "react";
import { CreatePrimaryToken, CreateSecondaryToken, getInfo, GetJustWhereUsers } from "../utilities/calls";
import { useNavigate,useParams } from "react-router-dom";
import { AiOutlineImport} from "react-icons/ai";
import {sanitizeEmail} from "../utilities/functions"

export default function OfferAddress() {
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const { addressId } = useParams();
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [confirmationMessage,setConfirmationMessage] = useState(false);
    const [suggestedEmail,setSuggestedEmail] = useState('');
    const [sendBtn,setSendBtn] = useState(true)
    const [contineAndSendBtn,setContinueAndSentBtn] = useState(false);   
    
    useLayoutEffect(() => {
    }, []);

    const offerAddress = () => {
        //create primary token with "" spid, current ind id, addrid
        //create secondary token with the user's email id, primary token generated above and addressid
        const individualid = getInfo("IndividualID");
        //todo check if the userEmail is jw user
        GetJustWhereUsers([userEmail]).then((resp) => {
          if(resp.validUserIds?.length === 0){
            const updatedUserEmail=sanitizeEmail(userEmail)
            GetJustWhereUsers([updatedUserEmail]).then((resp)=>{
               if(resp.validUserIds?.length > 0) {
                  setSuggestedEmail(resp.validUserIds[0])
                setConfirmationMessage(true)
                setContinueAndSentBtn(true)
                setSendBtn(false)
              }
              else {
                setShowAlert(true);
                setAlertText(userEmail + " is not a JustWhere user, you can only offer address to existing JustWhere User.");
                setSendBtn(false) 
            }
            }).catch((err) => {
              console.log(err);
              setShowAlert(true);
              setAlertText("Error occurred while verifiying justwhere user,please continue after some time");
            })
          }else 
            {
                CreatePrimaryToken("",addressId, individualid).then((resp) => {
                  CreateSecondaryToken("", userEmail, resp.token).then((resp) => {
                    setShowAlert(true);
                    setAlertText("Address shared with " + userEmail);
                    setSendBtn(false)
                  }).catch((err)=> console.log(err))
                }).catch((err)=> { console.log(err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                 })
            }
    }).catch((err) => {console.log(err)})
            
    }
    

    const cancelFn = () => {
       navigate(-1);
    }

    const handleInputChange = (event) => {
      setUserEmail(event.target.value)
    };
    const offerAddressWithSuggestedEmail=()=>{
    const individualid = getInfo("IndividualID");
    CreatePrimaryToken("",addressId, individualid).then((resp) => {
    CreateSecondaryToken("", suggestedEmail, resp.token).then((resp) => {
      setShowAlert(true);
      setAlertText("Address shared with " + suggestedEmail);
    }).catch((err)=> console.log(err))
  }).catch((err)=> { console.log(err);
      setAlertText("Error contacting Service Provider, please try again!");
      setShowAlert(true)
   })
   setConfirmationMessage(false)
   setContinueAndSentBtn(false)
   }
 

  

    return <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        <div className="mx-auto max-w-screen-lg form-container p-5">
            <div>
                <h1 className="form-label py-2">
                    <AiOutlineImport className="inline-icon" />
                    Send Address 
                </h1>
            </div>

            <div className="grid grid-cols-1">

                
            <div className="col-span-12 form-input px-2 py-2">
            <input
            type="text"
            value={userEmail}
            onChange={handleInputChange}
            placeholder="Enter Email"
            className="col-span-12 form-input px-2 py-2"
      />
            </div>
                <div className="flex flex-col gap-2">
                {
                    confirmationMessage &&
                    <div className="flex flex-col gap-2"> 
                    <p className="text-sm font-medium text-red-400">User with email "{userEmail}" is not registered with us but <span className="text-blue-500 hover:underline">"{suggestedEmail}"</span>{" "}is a JustWhere User.</p>
                    <p className="text-sm font-medium text-red-400">Do you want to share address with <span className="text-blue-500 hover:underline">"{suggestedEmail}"</span>{" "}instead?</p>
                    </div>
                  }
                <div class="flex justify-center items-center space-x-4 py-2 ">
                  {
                    contineAndSendBtn && 
                      <button onClick={offerAddressWithSuggestedEmail} type="submit" className="button-style positive-action">
                        Continue And Send
                    </button>
                    }
                    {sendBtn && 
                    <button onClick={offerAddress} type="submit" className="button-style positive-action">
                        Send
                    </button>
                    }
                    {sendBtn === true || contineAndSendBtn === true ? (
                        <button onClick={cancelFn} className="button-style">Cancel</button>
                    ) : (
                        <button onClick={cancelFn} className="button-style">Close</button>
                    )}
                    <div
                        class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                        role="alert"
                        style={{ display: showAlert ? "block" : "none" }}
                    >
                        <span class="text-sm">{alertText} </span>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}