import React from 'react'

const login = () => {
    const link = "/api/login";
    window.location.replace(link);
};

const Modal = ({ onClose }) => {
    return (

        <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">

            <div className='w-11/12 max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>

                <button className="absolute top-4 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={onClose}>X</button>


                <div className='flex flex-col gap-5 items-center p-6 mx-4 text-base font-normal text-white'>

                    <p className='font-medium'>JustWhere</p>

                    <p className='text-center'>By Continuing you agree to JustWhere

                        <span>{" "}</span>

                        <a href="#/policies/individualPolicy" target="_blank" rel="noopener noreferrer" className="text-blue-400">Terms Of Services</a>

                        <span>{" "}</span>

                        <span>and acknowledge JustWhere {" "}</span>



                        <a href="#/policies/privacyPolicy" target="_blank" rel="noopener noreferrer" className="text-blue-400">Privacy Policy</a>

                    </p>

                </div>


                <div className='flex justify-center items-center mb-10'>

                    <button class="gsi-material-button" onClick={login}>

                        <div class="gsi-material-button-state"></div>
                        <div className="gsi-material-button-content-wrapper">
                            <div className="gsi-material-button-icon">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                    <path fill="#34A853" d="M24 48c6.47 0 11.9-2.38 16.02-6.27l-7.73-6c-2.14 1.44-4.81 2.3-8.29 2.3-6.26 0-11.57-4.22-13.47-10.02l-7.97 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                </svg>
                            </div>
                            <span class="gsi-material-button-contents">
                                Continue with Google
                            </span>


                        </div>

                    </button>

                </div>



            </div>

        </div>
    )
}

export default Modal
