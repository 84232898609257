import React from "react";

class EllipsisText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipVisible: false,
    };
  }

  showTooltip = () => {
    this.setState({ isTooltipVisible: true });
  };

  hideTooltip = () => {
    this.setState({ isTooltipVisible: false });
  };

  render() {
    const { text, maxLength } = this.props;
    const { isTooltipVisible } = this.state;

    const displayText =
      text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;

    return (
      <span
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        style={{ cursor: "default", display: "inline-block" }}
      >
        {displayText}
        {isTooltipVisible && (
          <span
            style={{
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              maxWidth: "300px", // adjust the width as needed
              whiteSpace: "pre-wrap",
              zIndex: 9999,
              color: "#666",
            }}
          >
            {text}
          </span>
        )}
      </span>
    );
  }
}

export default EllipsisText;
