import { useLayoutEffect,  useState } from "react";
import { GetAccounts, GetAccount, GetSharesWithServiceProvider,  ConvertUnixTimestampToDateString, getInfo, FetchIndividual, GetServiceProvider } from "../utilities/calls";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";

export default function ServiceRequests() {
    const [individual, setIndividual] = useState({});
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderList, setServiceProviderList] = useState({});
    const [sharesReceivedSPList, setSharesReceivedSPList] = useState([]);


    useLayoutEffect(() => {
        const individualid = getInfo("IndividualID");
        if (individualid == null) return;
        FetchIndividual(individualid)
            .then((individualData) => {
                setIndividual(individualData);
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
                setShowAlert(true);
                setAlertText("Error fetching individual");
            });

        GetAccounts().then((response) => {
            console.log('GETAccounts response', response)
            Object.keys(response).forEach((key, index) => {
                GetAccount(key).then((accDetails) => {
                    Object.keys(accDetails.ServiceProviders).forEach((k, i) => {
                        GetServiceProvider(k).then((spDetails) => {
                            setServiceProviderList((serviceProviderList) => ({ ...serviceProviderList, [spDetails.id]: spDetails }));
                        })
                        GetSharesWithServiceProvider(k).then((share) => {
                            setSharesReceivedSPList((sharesReceivedSPList) => [...sharesReceivedSPList, ...share])
                        })
                        //TODO pull individual and address details for each record. 
                    })
                })
            })
        }).catch((error) => {
            console.error("Error fetching accounts:", error);
            setShowAlert(true);
            setAlertText("Error fetching data from server. Please try again!");
        });

    }, [])

    return individual && individual.id ?
        <div className="max-w-screen-xl grid grid-cols-1">
            <h1 className="py-3 bold-text-input">Activity</h1>
            <div>{showAlert ? (<div class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
                <span class="text-sm">{alertText} </span>
            </div>) : <></>}</div>
            {sharesReceivedSPList.map((request) => {
                 return <Link className="w-full flex flex-row hover:bg-555" to={{ pathname: "/spsharing" }} ><div class="w-full items-center justify-between p-4 border-t shadow-sm sm:flex border-gray-600">
                    <div class="hover:underline p-1 text-sm font-normal text-gray-300">{request?.tags?.name?.Value} <span class="text-xs font-normal me-2">has shared Address with {serviceProviderList[request.serviceProviderID]?.tags?.name?.Value}</span></div>
                    <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(request.createdAt)}>{dayjs().to(ConvertUnixTimestampToDateString(request.createdAt))}</time>
                </div></Link>
            })}

        </div>
        : <div></div>
}



