import { Link } from "react-router-dom";


export default function Footer() {

    return <footer class="shadow m-4 border-t-2 border-gray-700 ">
        <div class="w-full mx-auto max-w-screen-xl flex flex-col items-center justify-items-center gap-2 md:flex-row mt-2">

            <div className="text-sm text-gray-400">© 2024 <a href="https://justwhere.io/" class="hover:underline">JustWhere</a>. All Rights Reserved.</div>



            <ul className="flex flex-col gap-3 items-center justify-items-center max-w-max p-2 md:flex-row">
                <li>

                    <Link to="/aboutUs" className="text-center">About Us</Link>

                </li>
                <li>
                    <a href="#/policies/privacyPolicy" target="_blank" rel="noopener noreferrer" className="text-center">Privacy Policy</a>
                </li>

                <li>
                    <a href="#/policies/individualPolicy" target="_blank" rel="noopener noreferrer" className="text-center">Terms Of Service</a>
                </li>
                <li>
                    <a href="#/policies/beneficiaryPolicy" target="_blank" rel="noopener noreferrer" className="text-center">Terms of Service for Beneficiary</a>
                </li>
                <li>
                    <a href="#/policies/serviceProviderPolicy" target="_blank" rel="noopener noreferrer" className="text-center">Terms of Service for Service Provider</a>
                </li>
                <li>
                <a className="text-center" href="#/security-report" target="_blank" rel="noopener noreferrer">Security Reports</a>
                </li>

            </ul>



        </div>
    </footer>
}
