import React, { useEffect, useLayoutEffect, useState } from "react";
import { GetAddress, GetAccount, GetAccounts, GetSharesWithServiceProvider, GetServiceProvider, getInfo, GetBeneficiary } from "../utilities/calls";
import Login from "./Login";
import DropdownBenSearch from "./DropdownSearchBen";
import { useNavigate } from "react-router-dom";
const ServiceProviderShares = () => {
    const [addresses, setAddresses] = useState([]);
    const [shares, setShares] = useState({});
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderList, setServiceProviderList] = useState({});
    const [beneficiaryList, setBeneficiaryList] = useState([]);
    const [sharesWithBen, setSharesWithBen] = useState([]);
    const [benList, setBenList] = useState({});
    const navigate=useNavigate()

    useLayoutEffect(() => {
        setUserId(getInfo("UserID"));
    }, []);

    useLayoutEffect(() => {

        GetAccounts().then((response) => {
            console.log('GETAccounts response', response)
            Object.keys(response).forEach((key, index) => {
                GetAccount(key).then((accDetails) => {

                    Object.keys(accDetails.Beneficiaries).forEach((k, i) => {
                        GetBeneficiary(k).then((benDetails) => {
                            (
                                setBeneficiaryList((benList) => ([...benList, benDetails])))
                        })
                    })

                    Object.keys(accDetails.ServiceProviders).forEach((k, i) => {

                        GetServiceProvider(k).then((spDetails) => {
                            setServiceProviderList((serviceProviderList) => ({ ...serviceProviderList, [spDetails.id]: spDetails }));
                        })
                        GetSharesWithServiceProvider(k).then((share) => {
                            if (share.length > 0) {
                                
                                let ben = share.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0);
                                setSharesWithBen((sharesWithBen) =>  [...sharesWithBen, ...ben]);
                
                                setShares((shares) => ({ ...shares, [share[0].serviceProviderID]: share }));
                                Promise.all(
                                    share.map(async (el) => {
                                        try {
                                            if(el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0){
                                            const result = await GetAddress(el.addressID, el.serviceProviderID, el.token, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;
                                            } else {
                                                return;
                                            }
                                        } catch (err) {
                                            console.error("Error fetching addresses:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                )
                                    .then((addrs) => {
                                        setAddresses((addresses) => [...addresses, ...addrs]);
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setShowAlert(true);
                                        setAlertText("Error fetching data");
                                    });
                            }
                        })
                    })
                })
            })
        }).catch((error) => {
            console.error("Error fetching accounts:", error);
            setShowAlert(true);
            setAlertText("Error fetching data from server. Please try again!");
        });
    }, []);


    useEffect(() => {
        let benIds = []
        sharesWithBen.map((req) => {
        if(!benIds.includes(req.beneficiaryID)) {
          benIds.push(req.beneficiaryID);
          GetBeneficiary(req.beneficiaryID).then((el) => setBenList((benList) => ({...benList, [req.beneficiaryID] : el}))).catch((err) => console.log(err))
        }
        })
    }, [sharesWithBen])
    

    const Address = (addrId) => {
        if (addresses && addresses !== undefined && addresses.length > 0) {
            var address = addresses.find((ele) => addrId === ele?.id)

            return <div class="md:flex-1 flex-none min-w-0">
                <p class="text-sm font-medium text-white">
                    {address?.tags?.atag?.Name}
                </p>
                <p class="text-sm text-gray-400">
                    {address?.addressee}
                </p>
            </div>

        }
        else return <></>
    }
    const fetchBeneficiaryDetails=(benId)=>{
        console.log("The Ben Id is : ",benId)
        if(benId !== null && benId !== undefined){
            navigate(`/AddBeneficiaryProfile/${benId}`,{ state: { readOnly:true,showPrivate:true,previousPage: "/spsharing" } })
        }
    }


    return userId ? (

        <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >
                <span class="text-sm">{alertText} </span>
            </div>
      

            {Object.keys(shares).length > 0 ? (
            Object.keys(shares).map((k) => {
                return <div>
                    <h1 className="p-5 bold-text-input">Addresses Shared with {serviceProviderList[k]?.tags?.name?.Value}</h1>
                    <div class=" bg-gray  ">
                        <div class="p-5 max-w-full">
                            <ul class="max-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                {shares[k].map((s) => {
                                    var bens = sharesWithBen.filter((el)=> el.serviceProviderID === s.serviceProviderID && el.addressID === s.addressID)
                                    console.log(bens)
                                    return s?.tags?.RequestId?.Value ? <li class="py-3 md:py-4 grid grid-cols-1">
                                        <div class="flex flex-row">
                                        <div class="flex-shrink-0">
                                                <div class="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                                    <svg class="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                                                </div>
                                            </div>
                                        <div class="px-3 flex items-center justify-between space-x-4 rtl:space-x-reverse">
                                            {Address(s.addressID)}
                                        </div>
                                        </div>
                                        {bens.length >0 ? <div class="w-full mt-2 p-4 rounded-lg shadow-sm border border-gray-600" role="alert">
                <p class="flex text-sm">Your recommendation was accepted. <span class="text-blue-400">{ bens.map((b)=> {return <button onClick={()=>fetchBeneficiaryDetails(b.beneficiaryID)}>{"  " + benList[b.beneficiaryID]?.tags?.name?.Value+ " "}</button>})} (Beneficiary)</span> is now a connection!</p></div>
                                        :
                                        <div class="md:flex-1 flex-shrink-0 py-5">
                                                <DropdownBenSearch requestId={s?.tags?.RequestId?.Value} benList={beneficiaryList} />
                                            </div>
                                        }
                                    </li> : <></>
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            })):(<div className="w-full h-full flex items-center justify-center text-blue-700">
                <p className="text-center">No Shares Found</p>
                </div>)
            }
        </div>

    ) : (
        <Login />
    );
};


export default ServiceProviderShares;
