// src/App.js
import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import AddressList from "./components/AddressList";
import ViewAddressDetailsForRequest from "./components/ViewAddressDetailsForRequest";
import Requests from "./components/Requests";
import ProcessRequest from "./components/ProcessRequest";
import RequestAddress from "./components/CreateAddressRequest.js";
import Navigation from "./components/Navigation";
import EMailList from "./components/EMailList";
import AddressForm from "./components/AddressForm.js";
import AddressDetailsForm from "./components/AddressDetailsForm.js";
import "./App.css";
import { getInfo} from "./utilities/calls.js";
import Login from "./components/Login.js";
import DateTime from "./components/DateTimeDisplay";
import ServiceProviderList from "./components/ServiceProviderList.js";
import ServiceProviderProfileForm from "./components/ServiceProviderProfileForm.js";
import CopyToClipboard from "./components/CopyToClipboard.js";
import BeneProfileForm from "./components/BeneProfileForm.js";
import { BiLoaderCircle } from "react-icons/bi";
import AboutUs from "./components/AboutUs.js";
import Search from "./components/Search.js"
import SearchList from "./components/SearchList.js";
import ContactSP from "./components/ContactSP.js";
import ServiceProviderShares from "./components/ServiceProviderShares.js";
import IndividualShares from "./components/IndividualShares.js";
import ServiceProviderNotifications from "./components/ServiceProviderNotifications.js";
import Contact from "./components/Contact.js";
import IndividualNotifications from "./components/IndividualNotifications.js";
import NotificationIcon from "./components/NotificationIcon.js";
import OfferAddress from "./components/OfferAddress.js";
import PrivateServiceProviders from "./components/PrivateServiceProviders.js";
import PolicyViewer from "./components/PolicyViewer.js";
import SecurityReports from "./components/SecurityReports.js"

function App() {
  const [loginStatus, setLoginStatus] = useState(localStorage.getItem(document.location.hostname + '.loginStatus'));
  const [userid, setUserid] = useState(undefined);
  const [guid, setGuid] = useState();
  const [view, setView] = useState(localStorage.getItem(document.location.hostname + '.view'));
  
const toggleUserDD = () => {
    var dv = document.getElementById("user-dropdown");
    if (dv.classList.contains("hidden")) {
      dv.classList.add("block");
      dv.classList.remove("hidden");
    } else {
      dv.classList.add("hidden");
      dv.classList.remove("block");
    }
  }

  const changeView = (val) => {
    if(val === "I"){
      localStorage.setItem(document.location.hostname + '.view', 'I');
      setView('I');
    } else {
      localStorage.setItem(document.location.hostname + '.view', 'S');
      setView('S');
    }
  }

  useEffect(() => {
    setLoginStatus(localStorage.getItem(document.location.hostname + '.loginStatus'));
    setView(localStorage.getItem(document.location.hostname + '.view'))
  });

  useEffect(() => {
    document.title = "JustWhere-Securely Share, Track and Notify!";

    const userId = (decodeURIComponent(getInfo("UserID")));
    setUserid(decodeURIComponent(userId));
    setGuid(decodeURIComponent(getInfo("IndividualID")))
    if (userId == null || getInfo("IndividualID") == null) localStorage.setItem(document.location.hostname + '.loginStatus', 'LoggedOut'); else localStorage.setItem(document.location.hostname + '.loginStatus', 'LoggedIn');
  }, []);
  return (
    <Router>
      <div class="max-w-screen-xl px-4 py-3 mx-auto grid  grid-cols-1 md:grid-cols-2 justify-between items-center">
        <div className="flex flex-row ">
          <div className="flex-shrink-0 py-2 px-2 order-1">
          <button onClick={toggleUserDD} type="button" class=" bg-gray-800 rounded-lg md:me-0 focus:ring-4 focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
            <Link to="/"><img src="./justwhere.svg" alt="JustWhere" style={{ width: "2.5rem", height: "2.5rem" }} title="Securely Share, Track and Notify!" /></Link>
            </button>
            <div class="z-50 hidden my-4 text-base list-none divide-y rounded-lg shadow bg-gray-800 divide-gray-700" style={{ position: "absolute", top: "3rem" }} id="user-dropdown">
                <div class="px-4 py-3">
                  <span class="block text-xs text-white">                    <span id="indiId">{`${guid || "Not logged in!"}`}</span> <CopyToClipboard elId="indiId" />
                  </span>
                  <span class="py-1 block text-xs truncate text-gray-200">{`${userid || "Not logged in!"}`}</span>

                </div>
                <ul class="py-2">
                  <li class="px-4 text-xs text-gray-200"><DateTime /></li>
                </ul>
                </div>
          </div>
          {loginStatus === "LoggedIn" ? (
            <div className="flex flex-row order-2 p-2">
              <div class="inline-flex rounded-md shadow-sm" role="group">
              <Link to="/"><button onClick={()=> changeView('I')} class={ view !== "S" ? "focus:ring-2 focus:ring-gray-600 border-gray-700 bg-green-400 hover:bg-green-600 text-green-800 hover:text-white px-4 py-2 text-sm border rounded-s-lg" : "focus:ring-2 focus:ring-gray-600 border-gray-700  hover:bg-gray-600 px-4 py-2 text-sm  border rounded-s-lg"}>Individual</button></Link>
                   
              <Link to="/"><button onClick={()=> changeView('S')} class={ view === "S" ?  "focus:ring-2 focus:ring-gray-600 border-gray-700 bg-green-400  hover:bg-green-600 text-green-800 hover:text-white px-4 py-2 text-sm border rounded-e-lg" : "focus:ring-2 focus:ring-gray-600 border-gray-700  hover:bg-gray-600 px-4 py-2 text-sm border rounded-e-lg"}>Service Provider</button></Link>
              </div>
              
                <div className="p-2">
                  {
                    loginStatus === "LoggedIn" && <NotificationIcon/>
                  }
                </div>

              
            </div>
          ) : (<></>
          )}
        </div>
        <div className="flex px-4 py-3 md:order-2 order-3 col-span-3 md:col-span-1 justify-around rtl:space-x-reverse">
          {loginStatus === "LoggedIn" && view === "I" ? <Search /> : <></>}
        </div>

      </div><div style={{ marginTop: 5 }}>
        <Navigation />
        <BiLoaderCircle id="loadingIcon" style={{ "width": "1em", "height": "1em", "position": "fixed", "top": "50%", "left": "50%", "z-index": "100", "display": "none" }} />
        <Routes>
          <Route path="/" element={<Dashboard viewState={changeView} />} />
          <Route path="/address-list" element={<AddressList />} />
          <Route path="/address-details/:id" element={<AddressDetailsForm />} />
          <Route path="/viewrequestaddress-details/:requestid" element={<ViewAddressDetailsForRequest />} />
          <Route path="/CreateAddressRequest" element={<RequestAddress />} />
          <Route path="/AddAddressDetails" element={<AddressForm />} />
          <Route path="/process-request" element={<ProcessRequest />} />
          <Route path="/sharing" element={<IndividualShares />} />
          <Route path="/requests" element={<Requests />} />
          <Route path="/emaillist" element={<RequestAddress />} />
          <Route path="/contactlist" element={<EMailList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/serviceProviders" element={<ServiceProviderList />} />
          <Route path="/AddServiceProviderProfile" element={<ServiceProviderProfileForm />} />
          <Route path="/AddServiceProviderProfile/:spid" element={<ServiceProviderProfileForm />} />
          <Route path="/AddBeneficiaryProfile/:spid" element={<BeneProfileForm />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/search" element={<SearchList />} />
          <Route path="/contactServiceProvider/:id" element={<ContactSP />} />
          <Route path="/spsharing" element={<ServiceProviderShares />} />
          <Route path="/serviceprovidernotifications" element={<ServiceProviderNotifications />} />
          <Route path="/contacts" element={<Contact/>}/>
          <Route path="/individualnotifications" element={<IndividualNotifications/>}/>
          <Route path="/offerAddress/:addressId" element={<OfferAddress/>}/>
          <Route path="/privateserviceproviders" element={<PrivateServiceProviders/>}/>
          <Route path="/policies/:policyName" element={<PolicyViewer/>}/>
          <Route path="/security-report" element={<SecurityReports/>}/>
        </Routes>
      </div>
    </Router>
  );
}
export default App;
