import { useState } from "react";
import { GetRequestDetails, SearchBeneficiaries, UpdateRequest } from "../utilities/calls";
import { AiFillCheckCircle } from "react-icons/ai";
import CopyToClipboard from "./CopyToClipboard";
export default function DropdownBenSearch({requestId, benList}) {

const [resultsBen, setResultsBen] = useState([]);
const [selectedBen, setSelectedBen] = useState([]);
const [searchText, setSearchText] = useState();

const toggleDropdown = (ev) =>{
  

  var nameCheck = document.getElementsByName("benCheck");
  nameCheck.forEach((c) => {
    c.checked = false;
  })
    var t =  ev.target.closest('button');
    var dv = t.nextSibling;
    if (dv.classList.contains("hidden")) {
      var dropdowns = document.getElementsByName("dropdownSearch");
      dropdowns.forEach((d) => {
        d.classList.add("hidden");
        d.classList.remove("block");    
      })
      dv.classList.add("block");
      dv.classList.remove("hidden");
    } else {
      dv.classList.add("hidden");
      dv.classList.remove("block");
    }
    
    setSearchText("");
    setSelectedBen([])
} 

const updateList = (search) => {
    console.log(search);
    setSearchText(search)
    if(search.length > 0) {
    SearchBeneficiaries(search).then((resp) => { console.log("searchBen", resp); setResultsBen(resp) }).catch((err) => { console.log("Error search ben", err) })
    } else {
      setResultsBen([])
    }
}

const handleInputChange = (e) => {
    const {  value } = e.target;
    updateList(value);
  };

const toggleBenCheck = (e) => {
  console.log(e.target, selectedBen)
  if(e.target.checked) {
    setSelectedBen((selectedBen) => [...selectedBen, e.target.id])
  } else {
    setSelectedBen((selectedBen) => ( [...selectedBen.filter(ben => ben !== e.target.id) ]    ))
  }}

const forwardRef = (e) => {
  console.log(selectedBen)
  const icon =  e.target.parentElement.nextSibling;
  const note = e.target.parentElement.previousSibling.querySelector('#notes').value;
  const refBen =  {
      Name: "referenceBeneficiary",
      Value: selectedBen,
      Private: false,
      Required: false,
      Editable: false,
    };
  const notes = {
    Name: "note",
    Value: note,
    Private: false,
    Required: false,
    Editable: false,
  }
  GetRequestDetails(requestId).then((request)=> {request.tags.beneficiaryReference = refBen; request.tags.note = notes; UpdateRequest(request);  icon.style.display= "block"; const timer = setTimeout(() => {
    icon.style.display = "none"; 
}, 2000); } ).catch((err) => {console.log(err)});

}

return <> 
<button id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom" onClick={toggleDropdown} class="text-white w-full md:w-80 justify-center focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800" type="button">Refer a contact <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
</svg>
</button>

<div name="dropdownSearch" id="dropdownSearch" class="py-4 mb-4 z-10 hidden rounded-lg w-80 bg-gray-700" style={{ position: "absolute"}}>
    <div class="p-3">
      <label for="input-group-search" class="sr-only">Search</label>
      <div class="relative">
        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <input onChange={handleInputChange}  type="text" id="input-group-search" class="block w-full p-2 ps-10 text-sm border rounded-lg bg-gray-600 border-gray-500 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Search Beneficiary"/>
      </div>
    </div>
    <ul class="max-h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-200" aria-labelledby="dropdownSearchButton">
      {benList.filter((item) => item.tags?.name?.Value.toLowerCase().includes(searchText)).sort((a, b) => {return a.tags?.name?.Value.toLowerCase().localeCompare(b.tags?.name?.Value.toLowerCase())}).map((ben) => {
        return <li className="border-b border-gray-500 py-2  hover:bg-gray-600 rounded">
        <div class="flex items-center ps-2 ">
          <input name="benCheck" onChange={toggleBenCheck} id={ben.id} type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"/>
          <label class="w-full py-2 ms-2 text-sm font-medium rounded text-gray-300">{ben?.tags?.name?.Value || "No Name"}</label>
          <span class="text-xs font-medium me-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400">Preferred</span>
        </div>
        <label class="w-full text-xs  text-gray-300"><span id={"ben_"+ ben.id}>{ben.id}</span><CopyToClipboard elId={"ben_"+ben.id}/></label>
      </li>
      })}
      { resultsBen.filter((item) => benList.find((i)=> (i.id ===item.id)) === undefined).sort((a, b) => {return a.tags?.name?.Value.toLowerCase().localeCompare(b.tags?.name?.Value.toLowerCase())}).map((r)=> {return  <li className="border-b border-gray-500 py-2  hover:bg-gray-600 rounded">
        <div class="flex items-center ps-2 ">
          <input name="benCheck" onChange={toggleBenCheck} id={r.id} type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"/>
          <label for={r.id} class="w-full py-2 ms-2 text-sm font-medium rounded text-gray-300">{r?.tags?.name?.Value || "No Name"}</label>
        </div>
        <label class="w-full text-xs  text-gray-300"><span id={"ben_"+ r.id}>{r.id}</span><CopyToClipboard elId={"ben_"+r.id}/></label>
      </li> })}
    </ul>
    <div className="flex items-center p-3"><textarea name="notes" id="notes" placeholder="Add a note" cols="40" rows="3" /></div>
    <div className="flex items-center p-3"><button disabled={selectedBen.length === 0} onClick={forwardRef} class=" flex items-center p-3 text-sm font-medium rounded-lg bg-blue-500 hover:bg-blue-700 disabled:bg-gray-500 disabled:text-gray-100 text-white ">
      <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
        <path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z"/>
      </svg>
      Forward reference 
  </button></div>
  <div className="px-3 text-sm text-green-300" id="checked" style={{display: "none"}} ><AiFillCheckCircle color="green" style={{"padding-left":"0.1rem"}} className=" inline-icon"/>Reference Shared.</div>
</div>

</>
}