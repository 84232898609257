// src/components/Dashboard.js
import React, { useEffect, useState, useLayoutEffect } from "react";
import { AiFillCaretUp } from "react-icons/ai";
import {
  GetSentRequestsFrom,
  FetchIndividual,
  getInfo,
  GetShares,
  GetAccounts, GetAccount
  } from "../utilities/calls";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import RecentActivity from "./RecentActivity";
import Login from "./Login";
import ServiceProviderLogin from "./ServiceProviderLogin";
import Footer from "./Footer";
import ServiceRequests from "./ServiceRequests";


const Dashboard = ({viewState}) => {
  const [individual, setIndividual] = useState({});
  const [requestsSentCount, setSentRequetsCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [userId, setUserId] = useState();
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [previousAddressesCount, setPreviousAddressesCount] = useState(localStorage.getItem(document.location.hostname + ".addressesCount"));
  const [previousSentRequestCount, setPreviousSentRequestCount] = useState(localStorage.getItem(document.location.hostname + ".sentRequestCount"));
  const [previousSharesCount, setPreviousSharesCount] = useState(localStorage.getItem(document.location.hostname + ".sharesCount"));
  const [accounts, setAccounts] = useState({});
  const [account, setAccount] = useState({ServiceProviders: {}, Beneficiaries: {}});
  const [view, setView] = useState(localStorage.getItem(document.location.hostname + '.view'));
  const [individualNotificationCount, setIndividualNotificationCount] = useState(localStorage.getItem(document.location.hostname + '.individualNotificationsCount') ? localStorage.getItem(document.location.hostname + '.individualNotificationsCount') : 0)
  useLayoutEffect(() => {
    setUserId(getInfo("UserID"));
  }, []);

  useEffect(()=>{
    setView(localStorage.getItem(document.location.hostname +'.view'))  
  })

  useEffect(() => {
    const individualid = getInfo("IndividualID");
    if (individualid == null) return;
    FetchIndividual(individualid)
      .then((individualData) => {
        // Set the retrieved individual in the state
        setIndividual(individualData);
        const count = Object.keys(individualData.addresses || {}).length;
        localStorage.setItem(document.location.hostname + ".addressesCount", count);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual");
        if (error.response) {
          if (error.response.status === 401) {
          }
        }
      });

    GetSentRequestsFrom(individualid)
      .then((response) => {
        const count = response?.length || 0;
        setSentRequetsCount(count);
        localStorage.setItem(document.location.hostname + ".sentRequestCount", count);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
        setShowAlert(true);
        setAlertText("Error fetching requests");
      });

    GetShares(individualid)
      .then((sharesdata) => {
        const count = sharesdata?.length || 0;
        setShareCount(count);
        localStorage.setItem(document.location.hostname + ".sharesCount", count);
      })
      .catch((error) => {
        console.error("Error fetching shares:", error);
        setShowAlert(true);
        setAlertText("Error fetching shares");
      });

    //get accounts if accounts are found, check if user is service provider or beneficiary and display nav menu accordingly. 
    //if user has no accounts associated display service provider login tile. 
    GetAccounts().then((response) => {
      console.log('GETAccounts response', response)
      setAccounts(response);
      Object.keys(response).forEach((key, index) => {
        GetAccount(key).then((accDetails) => {
          setAccount(accDetails);
        }).catch((error) => {
          console.log("Error in Get Account details", error);
        });
      })

    }).catch((error) => {
      console.error("Error fetching accounts:", error);
      setShowAlert(true);
      setAlertText("Error fetching data from server. Please try again!");
    });
    
 }, []);
 useEffect(() => {
    const timer = setInterval(() => {
      setIndividualNotificationCount(localStorage.getItem(document.location.hostname + '.individualNotificationsCount'))
    }, 3600);
    return () => {
      clearInterval(timer)
    }
  }, [])


  return userId ? (
    <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
      {view === "S" ?
       <></> : <><h1 className="bold-text-input">Dashboard</h1>
          <div className="container grid grid-cols-1 md:grid-cols-3 gap-4">
            {Object.keys(accounts).length === 0 || (account && Object.keys(account.ServiceProviders).length === 0 && Object.keys(account.Beneficiaries).length === 0) ?
              <div className="dashboard-tile hover:bg-eee"><ServiceProviderLogin viewStateFn={viewState} /></div> :
              <></>
            }
            <Link className="dashboard-tile hover:bg-eee " style={{ display: "block" }} to="/address-list" title="My Addresses">
              <div>
                <h2># of Addresses</h2>
                <p>{Object.keys(individual.addresses || {}).length}</p>
                <p>{changeIndicator(previousAddressesCount, Object.keys(individual.addresses || {}).length)}</p>
              </div>
            </Link>
            <Link className="dashboard-tile hover:bg-eee " style={{ display: "block" }} to="/privateserviceproviders" title="My Service Providers">
              <div>
                <h2># of Private Service Providers</h2>
                <p>{Object.keys(individual.privateserviceproviders || {}).length}</p>
                <p></p>
              </div>
            </Link>
            <Link
              className="dashboard-tile hover:bg-eee" style={{ display: "block" }}
              to="/sharing"
              title="Sharing"
            >
              <div>
                <h2># of Addresses Shared with Others</h2>
                <p>{shareCount}</p>
                <p>{changeIndicator(previousSharesCount, shareCount)}</p>
              </div>
            </Link>
            <Link
              className="dashboard-tile hover:bg-eee" style={{ display: "block" }}
              to="/individualnotifications"
              title="Notifications"
            >
              <div>
                <h2>Notifications Received</h2>
                <p>{individualNotificationCount}</p>
              </div>
            </Link>
            <Link
              className="dashboard-tile hover:bg-eee" style={{ display: "block" }}
              to="/requests"
              title="Requests"
            >
              <div>
                <h2>Requests Sent</h2>
                <p>{requestsSentCount}</p>
                <p>{changeIndicator(previousSentRequestCount, requestsSentCount)}</p>
              </div>
            </Link>
          </div>
        </> 
      }
      {view === "S" ?
       <ServiceRequests /> 
        : <RecentActivity />
      }
      <Footer />
    </div>
  ) : (
    <Login />
  );
};


function changeIndicator(prevCount, currCount) {
  const curr = Number(currCount), prev = Number(prevCount);
  if (curr > prev) return <div> <AiFillCaretUp className="inline-icon" />
    {curr - prev} new updates since last time.</div>;
  return (<div></div>)
}

export default Dashboard;
