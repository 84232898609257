import React, { useEffect, useState,useLayoutEffect } from 'react'
import { GetNotifications, getInfo, GetRequestDetails, FetchIndividual, UpdateNotification,DeleteNotification } from "../utilities/calls"
import * as dayjs from "dayjs";
import { ConvertUnixTimestampToDateString } from "../utilities/calls"
import { BsCheckCircleFill } from "react-icons/bs";
import { BsCircle } from "react-icons/bs";
import { useNavigate} from "react-router-dom"
import { AiOutlineImport, AiOutlineProfile,AiOutlineShareAlt } from "react-icons/ai"
import { BsFillTrash3Fill } from "react-icons/bs";


const IndividualNotifications = () => {
    const [individual, setIndividual] = useState({});
    const [individualNotifications, setIndividualNotifications] = useState()
    const [newIndividualNotifications, setNewIndividualNotifications] = useState()
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const navigate = useNavigate()
    const[sortedIndividualNotifications,setSortedIndividualNotifications]=useState();
    const [isMark,setIsMark]=useState(false);
    const[userNotifications,setUserNotifications]=useState();
    const[sortedUserNotifications,setSortedUserNotifications]=useState()
    const[isMarkUser,setIsMarkUser]=useState(false)
    const[isIndNotificationDeleted,setIsIndNotificationDeleted]=useState(false)
    const[isUserNotificationDeleted,setIsUserNotificationDeleted]=useState(false)
    const[indNotificationsCount,setIndNotificationsCount]=useState(0)
    const[userNotificationsCount,setUserNotificationsCount]=useState(0)
    const freq = process.env.REACT_APP_NOTIFICATION_FREQUENCY;
    
    useLayoutEffect(() => {
        window.setTimeout(() => window.scrollTo(localStorage.getItem(document.location.hostname + ".notification.x"), localStorage.getItem(document.location.hostname + ".notification.y")),5000) ;
      }, []);
      useEffect(() => {
        const individualid = getInfo("IndividualID");
        if (individualid === null) return;
        FetchIndividual(individualid)
            .then((individualData) => {
                setIndividual(individualData);
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
                setShowAlert(true);
                setAlertText("Error fetching individual");
            });
        }, [])
        const saveCurrentState = (e) => {
        console.log(e)
        let x = e.screenX;  // Horizontal
        let y = e.screenY;  // Vertical
        localStorage.setItem(document.location.hostname + ".notification.x", x);
        localStorage.setItem(document.location.hostname + ".notification.y",y);
      } 
    const GetIndividualNotifications = () => {
        const individualid = getInfo("IndividualID");
        if (individualid === null) return;
        const entityType = "individual"

        GetNotifications(entityType, individualid).then((notificationsData) => {
            let newCount = 0;
            console.log("Individual Notifications Data : ", notificationsData)
            notificationsData.forEach((notification) => {
                if (notification.Status === "new") {
                  newCount++
                }
              })
            setIndividualNotifications(notificationsData)
            localStorage.setItem(document.location.hostname + ".individualNotificationsCount", newCount);
            setIndNotificationsCount(newCount)
            setIsIndNotificationDeleted(false)
            setIsMark(false)

        }).catch((error) => {

            setShowAlert(true);
            setAlertText("Error fetching data from server.Please try again");
        });
    };
    useEffect(() => {
        const updatedNotifications = individualNotifications?.map((notification) => {
              try {
                  const messageString = notification?.Message;
                  const jsonObject = JSON.parse(messageString);
                  return { ...notification, Message: jsonObject };
              } catch (e) {
                  console.log("Error occurred while parsing data: ", e.message);
                  return notification;
              }
          });
          setNewIndividualNotifications(updatedNotifications)
        }, [individualNotifications]);
      useEffect(()=>{
        if(newIndividualNotifications && newIndividualNotifications.length > 0) {
              const sortNotifications=newIndividualNotifications.sort((a, b) => new Date(b.Created) - new Date(a.Created));
              setSortedIndividualNotifications(sortNotifications)
          }
          else{
            setSortedIndividualNotifications([])
          }
      },[newIndividualNotifications])
      const GetUserNotifications=()=>{
        const userId = getInfo("UserID");
        if (userId === null) return;
        const entityType = "user";
        GetNotifications(entityType,userId).then((notificationsData)=>{
            let newCount = 0;
            console.log("User Notifications Data : ", notificationsData);
            notificationsData.forEach((notification) => {
                if (notification.Status === "new") {
                  newCount++
                }
              })
            setUserNotifications(notificationsData)
            localStorage.setItem(document.location.hostname + ".userNotificationsCount", newCount);
            setUserNotificationsCount(newCount)
            setIsUserNotificationDeleted(false)
            setIsMarkUser(false)
        }).catch((error) => {

            setShowAlert(true);
            setAlertText("Error fetching data from server.Please try again");
        });
    };
    useEffect(()=>{
        if(userNotifications && userNotifications.length > 0) {
            const sortNotifications=userNotifications.sort((a, b) => new Date(b.Created) - new Date(a.Created));
            setSortedUserNotifications(sortNotifications)
        }else{
            setSortedUserNotifications([])
        }
    },[userNotifications])
    useEffect(()=>{
        GetIndividualNotifications();
        GetUserNotifications();
        const timer = setInterval(() => {
            GetIndividualNotifications();
            GetUserNotifications();
        }, freq); 
        return () => clearInterval(timer);
    },[])
    const handlerOnClick = (notification, requestId, addressId,benId) => {
        if(benId !== null){
            navigate(`/AddBeneficiaryProfile/${benId}`,{ state: { readOnly:true,showPrivate:false,previousPage: "/individualnotifications" } })
            if (notification.Status === "new") {
                let formData = {
                    Message: notification.Message.Message,
                    Status: "processed",
                    ExternalID: notification.ExternalID
                }
                const entityType = "individual"
                UpdateNotification(entityType, notification.id, formData).then((response) => {
                    console.log("The Response is : ", response)
                }).catch((err) => {
                    console.log("The Error is : ", err)
                    setShowAlert(true);
                    setAlertText("Error fetching data from server.Please try again");
                })
            }
        }
        else if (addressId !== null) {
            navigate("/sharing")
            if (notification.Status === "new") {
                let formData = {
                    Message: notification.Message.Message,
                    Status: "processed",
                    ExternalID: notification.ExternalID
                }
                const entityType = "individual"
                UpdateNotification(entityType, notification.id, formData).then((response) => {
                    console.log("The Response is : ", response)
                }).catch((err) => {
                    console.log("The Error is : ", err)
                    setShowAlert(true);
                    setAlertText("Error fetching data from server.Please try again");
                })
            }
        }
        else if (requestId !== null) {
            GetRequestDetails(requestId).then((requestDetails) => {
                if (requestDetails.status === "NEW") {

                    navigate("/process-request", { state: { request: requestDetails, addresses: individual.addresses, previousPage: "/individualnotifications" } })
                    if (notification.Status === "new") {
                        let formData = {
                            Message: notification.Message.Message,
                            Status: "processed",
                            ExternalID: notification.ExternalID
                        }
                        const entityType = "individual"
                        UpdateNotification(entityType, notification.id, formData).then((response) => {
                            console.log("The Response is : ", response)
                        }).catch((err) => {
                            console.log("The Error is : ", err)
                            setShowAlert(true);
                            setAlertText("Error fetching data from server.Please try again");
                        })
                    }
                } else {
                    navigate(`/viewrequestaddress-details/${requestId}`, { state: { previousPage: "/individualnotifications" } });
                    if (notification.Status === "new") {
                        let formData = {
                            Message: notification.Message.Message,
                            Status: "processed",
                            ExternalID: notification.ExternalID
                        }
                        const entityType = "individual"
                        UpdateNotification(entityType, notification.id, formData).then((response) => {
                            console.log("The Response is : ", response)
                        }).catch((err) => {
                            console.log("The Error is : ", err)
                            setShowAlert(true);
                            setAlertText("Error fetching data from server.Please try again");
                        })

                    }
                }

            }).catch((error) => {
                setShowAlert(true);
                setAlertText("Error fetching data from server.Please try again")
            })

        }else{
            navigate("/sharing")
            if (notification.Status === "new") {
                let formData = {
                    Message: notification.Message.Message,
                    Status: "processed",
                    ExternalID: notification.ExternalID
                }
                const entityType = "individual"
                UpdateNotification(entityType, notification.id, formData).then((response) => {
                    console.log("The Response is : ", response)
                }).catch((err) => {
                    console.log("The Error is : ", err)
                    setShowAlert(true);
                    setAlertText("Error fetching data from server.Please try again");
                })

            }
        }
    }
    

   const handleOnMarked=(notification)=>{
        if(notification?.Message?.Tags === undefined){
            let formData = {
                Message: notification.Message,
                Status: "processed",
                ExternalID: notification.ExternalID
            }
            const entityType = "individual"
            UpdateNotification(entityType, notification.id, formData).then((response) => {
                console.log("The Response is : ", response)
                setIsMark(true)
            }).catch((err) => {
                console.log("The Error is : ", err)
                setShowAlert(true);
                setAlertText("Error fetching data from server.Please try again");
            })
        }
        else if(notification?.Message?.Tags?.AddressId?.Value === null && 
            notification?.Message?.Tags?.BeneficiaryId?.Value === null && 
            notification?.Message?.Tags?.RequestId?.Value === null && 
            notification?.Message?.Tags?.RequestStatus?.Value === null 
         ){
             let formData = {
                 Message: notification.Message.Message,
                 Status: "processed",
                 ExternalID: notification.ExternalID
             }
             const entityType = "individual"
             UpdateNotification(entityType, notification.id, formData).then((response) => {
                 console.log("The Response is : ", response)
                 setIsMark(true)
             }).catch((err) => {
                 console.log("The Error is : ", err)
                 setShowAlert(true);
                 setAlertText("Error fetching data from server.Please try again");
             })
            }

    }
const handlerOnUserMark=(notification)=>{
        let formData = {
            Message: notification.Message,
            Status: "processed",
            ExternalID: notification.ExternalID
        }
        const entityType = "user"
        UpdateNotification(entityType, notification.id, formData).then((response) => {
            console.log("The Response is : ", response)
            setIsMarkUser(true)
        }).catch((err) => {
            console.log("The Error is : ", err)
            setShowAlert(true);
            setAlertText("Error fetching data from server.Please try again");
        })
    }
    useEffect(()=>{
        if(isMark === true){
        GetIndividualNotifications()
        }
    },[isMark])
    useEffect(()=>{
        if(isMarkUser === true){
        GetUserNotifications();
    }
     },[isMarkUser])

     const deleteIndividualNotification=(notification)=>{
        console.log("The notification Id is :",notification.id)
        const entityType = "individual";
        DeleteNotification(entityType, notification.id).then((response)=>{
            console.log("The Response is : ",response)
            setIsIndNotificationDeleted(true)
        }).catch((error)=>{
            console.log("The Error is : ", error)
            setShowAlert(true);
            setAlertText("Error Occurred while deleting Notification");
        })
       
    }

    const deleteUserNotification=(notification)=>{
        const entityType = "user";
        DeleteNotification(entityType,notification.id).then((response)=>{
            console.log("The response is:",response)
            setIsUserNotificationDeleted(true)
        }).catch((error)=>{
            console.log("The Error is : ", error)
            setShowAlert(true);
            setAlertText("Error Occurred while deleting Notification");
        })
    }

    useEffect(()=>{
        if(isIndNotificationDeleted === true){
        GetIndividualNotifications()
    }
    
    },[isIndNotificationDeleted])

    useEffect(()=>{
        if(isUserNotificationDeleted === true){
        GetUserNotifications()
        
     }
    },[isUserNotificationDeleted])

    return (

        <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">

            <h1 className="bold-text-input">Notifications</h1>
            <div id="accordion-collapse" data-accordion="collapse" className="py-5">

               <h2 id="accordion-collapse-heading-1">
                <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                <div className='flex justify-items-center items-center gap-2'>
                    <span className='text-lg font-bold'>{`Individual Notifications ${sortedIndividualNotifications !== undefined && sortedIndividualNotifications !== null ? sortedIndividualNotifications.length:0}`}</span>
                {indNotificationsCount > 0 && (<span className='text-xs font-medium me-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400'>{ indNotificationsCount + " " + "new"}</span>)}
                </div>
                <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-1") == null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" : "w-3 h-3 shrink-0" } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
                </button>
               </h2>
               <div id="accordion-collapse-body-1" class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-1") == null ? "hidden" : localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-1")} aria-labelledby="accordion-collapse-heading-1">
            <div className='p-5 border border-gray-700'>
            {sortedIndividualNotifications === undefined || sortedIndividualNotifications.length === 0 ? <>No Notifications</> :
                <ul className='w-full divide-y divide-gray-700'>
                    {
                        sortedIndividualNotifications?.map((notification) => {
                            return (
                                <li className='py-2' key={notification.id}>
                                    {
                                        notification.Status === "new" ? (
                                            <>
                                            <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">
                                                    <div className="order-2 md:order-1 flex-1">
                                                        {
                                                            notification?.Message?.Message?.length > 0 ?
                                                                (
                                                                    <p className='text-sm font-medium text-white'>{notification.Message.Message}</p>
                                                                ) : (
                                                                    <p className='text-sm font-medium text-white'>{notification.Message}</p>
                                                                )
                                                        }
                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                        <NotificationStatus status={notification.Status}/>
                                                        <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                    </div>
                                                    </div>

                                                <div className="flex items-center justify-between">
                                                    {
                                                        notification?.Message?.Tags?.RequestStatus?.Value === "NEW" &&
                                                            <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnClick(notification, notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value,notification?.Message?.Tags?.BeneficiaryId.Value);saveCurrentState(e)}}>
                                                                <AiOutlineImport size={10} className="inline-icon" />
                                                                View Request
                                                            </button>
                                                     }{
                                                        notification?.Message?.Tags?.RequestStatus?.Value === "ACCEPTED" &&
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnClick(notification, notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value,notification?.Message?.Tags?.BeneficiaryId.Value);saveCurrentState(e)}}>
                                                            <AiOutlineProfile className="inline-icon" />
                                                            View Address
                                                        </button>
                                                      }    
                                                         
                                                    {
                                                         notification?.Message?.Tags?.RequestStatus?.Value === null && notification?.Message?.Tags?.AddressId?.Value !== null  &&
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnClick(notification, notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value,notification?.Message?.Tags?.BeneficiaryId.Value);saveCurrentState(e)}}>
                                                        <AiOutlineShareAlt className="inline-icon" />
                                                         View Shares
                                                       </button>
                                                    }
                                                    {
                                                        notification?.Message?.Tags?.RequestStatus?.Value === null && notification?.Message?.Tags?.BeneficiaryId?.Value !== null && 
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnClick(notification, notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value,notification?.Message?.Tags?.BeneficiaryId.Value);saveCurrentState(e)}}>
                                                        <AiOutlineProfile className="inline-icon" />
                                                         View Beneficiary
                                                       </button>
                                                    }
                                                    {
                                                        notification?.Message?.Tags?.AddressId?.Value === null && 
                                                        notification?.Message?.Tags?.BeneficiaryId?.Value === null && 
                                                        notification?.Message?.Tags?.RequestId?.Value === null && 
                                                        notification?.Message?.Tags?.RequestStatus?.Value === null &&
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnClick(notification, notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value,notification?.Message?.Tags?.BeneficiaryId.Value);saveCurrentState(e)}}>
                                                        <AiOutlineShareAlt className="inline-icon" />
                                                            View Shares
                                                        </button>

                                                    }
                                                    {
                                                        notification?.Message?.Tags === undefined && <button className='text-blue-500 bg-slate-333 hover:bg-555 hover:underline' onClick={(e)=>{handleOnMarked(notification);saveCurrentState(e)}}>Mark as Read</button>
                                                    }
                                                     <div class="py-3 grid grid-cols-1 justify-items-end justify-end  rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={(e)=>{deleteIndividualNotification(notification);saveCurrentState(e)}}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                            </div>
                                                </>
                                                ) : (
                                            <>
                                            <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between">

                                                <div className="order-2 md:order-1 flex-1">
                                                    <p className='text-sm font-medium text-white'>{notification.Message}</p>
                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                    <NotificationStatus status={notification.Status} />
                                                    <time class="text-xs font-normal text-gray-400" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                        </div>
                                                        </div>

                                                <div class="py-3 grid grid-cols-1 justify-items-end justify-end rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={(e)=>{deleteIndividualNotification(notification);saveCurrentState(e)}}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                            </>

                                        )
                                    }
                                </li>
                            )

                        })
                    }
                </ul>
              
              }
              </div>
        </div>
        <h2 id="accordion-collapse-heading-2">
              <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-2" onClick={handleAccordionClick}>
              <div className='flex justify-items-center items-center gap-2'>
                <span className='text-lg font-bold'>{`User Notifications ${sortedUserNotifications !== undefined && sortedUserNotifications !== null ? sortedUserNotifications.length:0}`}</span>
              {userNotificationsCount > 0 && (<span className='text-xs font-medium me-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400'>{ userNotificationsCount + " " + "new"}</span>)}
              </div>
                <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-2") === null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" :  "w-3 h-3 shrink-0" } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
              </button>
               </h2>
               <div id="accordion-collapse-body-2" class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-2") == null ? "hidden" : localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-2")} aria-labelledby="accordion-collapse-heading-2">
                <div className='p-5 border border-gray-700'>
                {sortedUserNotifications === undefined || sortedUserNotifications.length === 0 ? <>No Notifications</> :
                <ul className='w-full divide-y divide-gray-700'>
                {
                    sortedUserNotifications?.map((notification) => {
                        return (
                            <li className='py-2'>
                                {
                                    notification.Status === "new" ? (
                                        <>
                                        <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">
                                                <div className="order-2 md:order-1 flex-1">
                                                    <p className='text-sm font-medium text-white'>{notification.Message}</p>
                                                </div>
                                                <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                <NotificationStatus status={notification.Status} />
                                                <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                </div>
                                               </div>
                                               <div className="flex items-center justify-between">
                                                <div>
                                                <button className='text-blue-500 bg-slate-333 hover:bg-555 hover:underline' onClick={(e)=>{handlerOnUserMark(notification);saveCurrentState(e)}}>Mark as Read</button>
                                                </div>
                                                 <div class="py-3 grid grid-cols-1 justify-items-end justify-end rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={()=>deleteUserNotification(notification)}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                            </div>
                                                            </>
                                                    ) : (
                                                        <>
                                                        <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">
                                                <div className="order-2 md:order-1 flex-1">
                                                    <p className='text-sm font-medium text-white'>{notification.Message}</p>
                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                    <NotificationStatus status={notification.Status} />
                                                    <time class="text-xs font-normal text-gray-400" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                    </div>
                                                    </div>
                                                    <div class="py-3 grid grid-cols-1 justify-items-end justify-end rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={()=>deleteUserNotification(notification)}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                            </>
                                                )
                                }
                            </li>
                        )
                    })
                }
            </ul>
          }
          </div>
          </div>
        </div>
        </div>
    )
}

function NotificationStatus({ status, title }) {
    switch (status) {
        case "processed":
            return <BsCheckCircleFill fill="green" title="Read" />;
        case "new":
            return <BsCircle fill="green" title="Unread" />;
        default:
            return "";
    }
}
function handleAccordionClick(ev){
    let dv, icon;
    if(ev.target.dataset && ev.target.dataset.accordionTarget){
      dv = document.getElementById(ev.target.dataset.accordionTarget);
      icon = ev.target.querySelector("svg");
    } else {
      const button = ev.target.closest("button");
      dv = document.getElementById(button.dataset.accordionTarget);
      icon = button.querySelector("svg");
    }
    if(dv.classList.contains("hidden")) {
      localStorage.setItem(document.location.hostname + '.notification.accordion.' + dv.id, 'block' )
  
      dv.classList.add("block"); 
      dv.classList.remove("hidden");
      icon.classList.remove("rotate-180");
    } else {
      localStorage.setItem(document.location.hostname + '.notification.accordion.' + dv.id, 'hidden' )
  
      dv.classList.add("hidden");
      dv.classList.remove("block");
      icon.classList.add("rotate-180");
    }
  }
export default IndividualNotifications

