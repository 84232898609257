import * as dayjs from "dayjs";
import { BsFillTrash3Fill } from "react-icons/bs";


function renderField(label, id, type, val, onChange, options, tooltip, readonly) {
  switch (type) {
    case "select":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <select onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input">
            {options.map((element) => {
              return <option value={element.value}>{element.name}</option>;
            })}
          </select>
        </div>
      );
    case "alphanumeric":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <input readOnly={readonly} type="text" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input" />
        </div>
      );
    case "textarea":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <textarea onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input" />
        </div>
      );
    case "checkbox":
      return (
        <div className="grid grid-cols-12">
          <input type="checkbox" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-1 py-2 px-2 form-input" />
          <label className="col-span-11 form-label px-2" htmlFor={id}>
            {label}
          </label>
          <label class="px-1 col-span-12 form-label">{tooltip ? tooltip : <></>}</label>
        </div>
      );
    default:
      return (
        <div className=" grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <input type="text" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input" />
        </div>
      );
  }
}


function renderTag(label, id, val, isPrivate, onChange, onDelete) {
  return (
    <div className="grid grid-cols-12">
      <label className="col-span-6 form-label" htmlFor={id}>
        {label}:
      </label>
      <input type="checkbox" onChange={onChange} id={id} name={id} checked={isPrivate} placeholder={label} className="col-span-1 py-2 px-2 form-input" />
      <label className="col-span-5 form-label px-2" htmlFor={id}>Private (Only visible to you.)</label>
      <input type="text" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-11 px-2 py-2 form-input" />
      <button formMethod="dialog" formTarget="top" name={id} className="col-span-1 bg-slate-333 py-1 hover:bg-555" onClick={onDelete}>
        <BsFillTrash3Fill name={id} title="Delete" className="inline-icon" />
      </button>
    </div>
  );
}

function renderData(label, val) {
  return (
    <div className="grid grid-cols-12">
      <label className="col-span-5 form-label text-right">
        {label}:
      </label>
      <span className="col-span-1" />
      <label className="col-span-6 text-left" >{val}</label>
    </div>
  );
}

function displayDate(val){
  return dayjs().diff(dayjs(val), "week") > 1 ? " on " +dayjs(val).format("MMM D, YYYY")  : dayjs(val).fromNow()
}
function sanitizeEmail(email) {
  return email.trim().toLowerCase();
}

function displayPhoneLink(val) {
  if(val === undefined || val === null) return "";
  const tel = val.replaceAll(' ', '');
  return <a href={"tel:" + tel}>{val}</a>
}

function displayWeblink(val) {
  if(val === undefined || val === null) return "";
  const http= "http://";
  const https="https://";
  if(!val.toLowerCase().startsWith(http) && !val.toLowerCase().startsWith(https)){
    return <a href={https + val} target="_blank" rel="noopener noreferrer">{val}</a>
  }
  return <a href={val} target="_blank" rel="noopener noreferrer">{val}</a>
}

function displayEmaillink(val){
  if(val === undefined || val === null) return "";
  if(isMobile()) 
    return <a href={"mailto:" + val} target="_blank" rel="noopener noreferrer">{val}</a>
  else 
  return <a href={"https://mail.google.com/mail/u/0/?fs=1&tf=cm&to="  + val} target="_blank" rel="noopener noreferrer">{val}</a>
}

function isMobile() {
	const useragent = navigator.userAgent.toLowerCase();
	console.log("Client is using user Agent %s", useragent)
	return useragent.includes("android") || useragent.includes( "iphone") || useragent.includes("ipad")
  }


export { renderField, renderTag, renderData , displayDate,sanitizeEmail, isMobile, displayPhoneLink, displayWeblink, displayEmaillink}