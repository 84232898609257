import React, { useEffect, useLayoutEffect, useState } from 'react'
import { GetNotifications, GetAccount,GetAccounts, getInfo,UpdateNotification,DeleteNotification } from "../utilities/calls";
import * as dayjs from "dayjs";
import { ConvertUnixTimestampToDateString } from "../utilities/calls"
import { BsCheckCircleFill } from "react-icons/bs";
import { BsCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom"
import { AiOutlineImport, AiOutlineProfile,AiOutlineShareAlt } from "react-icons/ai"
import { BsFillTrash3Fill } from "react-icons/bs";

const ServiceProviderNotifications = () => {
    const [spNotifications, setSPNotifications] = useState([])
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderAccount, setServiceProviderAccount] = useState([]);
    const [newSPNotifications, setNewSPNotifications] = useState()
    const [accounts, setAccounts] = useState([]);
    const[beneficiaryAccount,setBeneficiaryAccount]=useState([])
    const[beneficiaryNotifications,setBeneficiaryNotifications]=useState([])
    const[newBenNotifications,setNewBenNotifications]=useState()
    const[sortedSPNotifications,setSortedSPNotifications]=useState()
    const[sortedBenNotifications,setSortedBenNotifications]=useState();
    const[isMarkSP,setIsMarkSP]=useState(false)
    const[isMarkBen,setIsMarkBen]=useState(false)
    const[isSPNotificationDeleted,setIsSPNotificationDeleted]=useState(false)
    const[isBenNotificationDeleted,setIsBenNotificationDeleted]=useState(false)
    const[spNotificationsCount,setSPNotificationsCount]=useState(0)
    const[benNotificationsCount,setBenNotificationsCount]=useState(0)
    const freq = process.env.REACT_APP_NOTIFICATION_FREQUENCY;
    const navigate = useNavigate()

    useLayoutEffect(() => {
        window.setTimeout(() => window.scrollTo(localStorage.getItem(document.location.hostname + ".notification.x"), localStorage.getItem(document.location.hostname + ".notification.y")),5000) ;
      }, []);

      const saveCurrentState = (e) => {
        console.log(e)
        let x = e.screenX;  // Horizontal
        let y = e.screenY;  // Vertical
        localStorage.setItem(document.location.hostname + ".notification.x", x);
        localStorage.setItem(document.location.hostname + ".notification.y",y);
      } 
    const fetchAccounts=()=>{
    const individualid = getInfo("IndividualID");
    if (individualid === null) return;
    GetAccounts().then((response) => {
      console.log('GETAccounts response', response)
      const accountPromises=Object.keys(response).map((key)=>{
        return GetAccount(key).catch((error) => {
          console.log(`Error in Getting Account details for ID ${key}`, error);
          return null
        });
      });
      Promise.all(accountPromises).then((accounts)=>{
        const validAccounts=accounts.filter((account) => account !== null)
        setAccounts(validAccounts)
      }).catch((error)=>{
        console.log("Error Getting account Details ",error);
        setShowAlert(true);
        setAlertText("Error fetching data from server. Please try again!");
      })
    }).catch((error) => {
      console.error("Error fetching accounts:", error);
      setShowAlert(true);
      setAlertText("Error fetching data from server. Please try again!");
    });
    }
    
    useEffect(()=>{
       fetchAccounts()
    },[])
    useEffect(() => {
        GetServiceProviderNotifications();
        GetBeneficiaryNotifications();
        const timer = setInterval(() => {
            GetServiceProviderNotifications();
            GetBeneficiaryNotifications();
        }, freq); 
        return () => clearInterval(timer);
      }, [serviceProviderAccount,beneficiaryAccount]);

      useEffect(() => {
        const newServiceProviderAccounts = [];
        const newBeneficiaryAccounts = [];
        accounts.forEach((account) => {
            newServiceProviderAccounts.push(account?.ServiceProviders);
            newBeneficiaryAccounts.push(account?.Beneficiaries);
          });
          setServiceProviderAccount(newServiceProviderAccounts);
          setBeneficiaryAccount(newBeneficiaryAccounts);
      }, [accounts]);

      const GetServiceProviderNotifications = () => {
        let newCount = 0;
        const entityType = "serviceprovider"
        if(serviceProviderAccount !== undefined && serviceProviderAccount !== null){
            serviceProviderAccount.forEach((sp)=>{
            const notificationPromises=Object.keys(sp).map((serviceProviderId)=>{
              return GetNotifications(entityType, serviceProviderId).catch((error)=>{console.log(`Error in fetching Notifications for Service Provider of ID ${serviceProviderId}`)
                return null
              });
            });
            Promise.all(notificationPromises).then((notifications)=>{
              const validNotifications=notifications.filter((notifications)=>notifications !== null)
              validNotifications.forEach((notificationArr)=>{
                notificationArr.forEach((notification)=>{
                  if(notification.Status === "new"){
                    newCount=newCount+1
                  }
                })
              })
              localStorage.setItem(document.location.hostname + ".serviceProviderNotificationCount", newCount);
              setSPNotifications(validNotifications)
              setSPNotificationsCount(newCount)
              setIsSPNotificationDeleted(false)
              setIsMarkSP(false)
            }).catch((error)=>{
              console.log("Error in Getting SP Notifications",error);
              setShowAlert(true);
              setAlertText("Error fetching data from server.Please try again");
            });
            })
      }
    }
      
    const GetBeneficiaryNotifications=()=>{
        let newCount = 0;
        const entityType = "beneficiary"
        if(beneficiaryAccount !== undefined && beneficiaryAccount !== null){
          beneficiaryAccount.forEach((ben)=>{
            const notificationPromises=Object.keys(ben).map((benId)=>{
              return GetNotifications(entityType, benId).catch((error)=>{
                console.log(`Error in fetching Notifications for Benificiary of ID ${benId}`)
                return null
              })
            })
            Promise.all(notificationPromises).then((notifications)=>{
              const validNotifications=notifications.filter((notifications)=>notifications !== null)
              validNotifications.forEach((notificationArr)=>{
                notificationArr.forEach((notification)=>{
                  if(notification.Status === "new"){
                    newCount=newCount+1
                  }
                })
              })
              localStorage.setItem(document.location.hostname + ".beneficiaryNotificationsCount", newCount);
              setBeneficiaryNotifications(validNotifications)
              setBenNotificationsCount(newCount)
              setIsBenNotificationDeleted(false)
              setIsMarkBen(false)
            })
            .catch((error) => {
              console.log("Error in Getting Ben Notifications",error);
              setShowAlert(true);
              setAlertText("Error fetching data from server.Please try again");
            });
          })
        }
      }
      
    
    
    useEffect(() => {
        let newNotificationsArray = []
        spNotifications?.map((notificationArr) => {

            if (notificationArr?.length > 0) {
                notificationArr.forEach((notification) => {
                    try {
                        const messageString = notification?.Message;
                        console.log("The message string is: ", messageString);
                        const jsonObject = JSON.parse(messageString);
                        notification.Message = jsonObject;
                        newNotificationsArray.push(notification)
                    } catch (e) {
                        console.log("Error occurred while parsing data: ", e.message);
                        newNotificationsArray.push(notification);
                    }
                }
                )
            }

        });
        console.log("The newNotifications array is : ", newNotificationsArray)
        setNewSPNotifications(newNotificationsArray)

    }, [spNotifications]);
    useEffect(()=>{
        if(newSPNotifications && newSPNotifications.length > 0) {
            const sortNotifications=newSPNotifications.sort((a, b) => new Date(b.Created) - new Date(a.Created));
            setSortedSPNotifications(sortNotifications)
        }else{
            setSortedSPNotifications([])
        }
    },[newSPNotifications])
    
    const handlerOnMarkSP=(notification)=>{
        if(notification?.Message?.Tags === undefined){
                            let formData = {
                                Message: notification.Message,
                                Status: "processed",
                                ExternalID: notification.ExternalID
                            }
                            const entityType = "serviceprovider"
                            UpdateNotification(entityType, notification.id, formData).then((response) => {
                                console.log("The Response is : ", response)
                                setIsMarkSP(true)
                            }).catch((err) => {
                                console.log("The Error is : ", err)
                                setShowAlert(true);
                                setAlertText("Error fetching data from server.Please try again");
                            })
                        }

    }

    useEffect(()=>{
        let newNotificationsArray = []
        beneficiaryNotifications?.map((notificationArr) => {

            if (notificationArr?.length > 0) {
                notificationArr.forEach((notification) => {
                    try {
                        const messageString = notification?.Message;
                        console.log("The message string is: ", messageString);
                        const jsonObject = JSON.parse(messageString);
                        notification.Message = jsonObject;
                        newNotificationsArray.push(notification)
                    } catch (e) {
                        console.log("Error occurred while parsing data: ", e.message);
                        newNotificationsArray.push(notification);
                    }
                }
                )
            }

        });
        console.log("The newNotifications array is : ", newNotificationsArray)
        setNewBenNotifications(newNotificationsArray)


    },[beneficiaryNotifications])
    useEffect(()=>{
        if(newBenNotifications && newBenNotifications.length > 0) {
            const sortNotifications=newBenNotifications.sort((a, b) => new Date(b.Created) - new Date(a.Created));
            setSortedBenNotifications(sortNotifications)
        }else{
            setSortedBenNotifications([])
        }
    },[newBenNotifications])
    
    const handleOnMarkBen=(notification)=>{
        if(notification?.Message?.Tags === undefined){
                            let formData = {
                                Message: notification.Message,
                                Status: "processed",
                                ExternalID: notification.ExternalID
                            }
                            const entityType = "beneficiary"
                            UpdateNotification(entityType, notification.id, formData).then((response) => {
                                console.log("The Response is : ", response)
                                setIsMarkBen(true)
                            }).catch((err) => {
                                console.log("The Error is : ", err)
                                setShowAlert(true);
                                setAlertText("Error fetching data from server.Please try again");
                            })
                        }

    }
    const handlerOnSPClick = (notification,requestId,addressId) => {
        if (addressId !== null){
            navigate("/spsharing")
            if (notification.Status === "new") {
                let formData = {
                    Message: notification.Message.Message,
                    Status: "processed",
                    ExternalID: notification.ExternalID
                }
                const entityType = "serviceprovider"
                UpdateNotification(entityType, notification.id, formData).then((response) => {
                    console.log("The Response is : ", response)
                }).catch((err) => {
                    console.log("The Error is : ", err)
                    setShowAlert(true);
                    setAlertText("Error fetching data from server.Please try again");
                })
            }
        }
        
        else if(requestId !== null){
            console.log("The requestId is : ",requestId)
            navigate(`/viewrequestaddress-details/${requestId}`, { state: { previousPage: "/serviceprovidernotifications" } });
            if (notification.Status === "new") {
                let formData = {
                    Message: notification.Message.Message,
                    Status: "processed",
                    ExternalID: notification.ExternalID
                }
                const entityType = "serviceprovider"
                UpdateNotification(entityType, notification.id, formData).then((response) => {
                    console.log("The Response is : ", response)
                }).catch((err) => {
                    console.log("The Error is : ", err)
                    setShowAlert(true);
                    setAlertText("Error fetching data from server.Please try again");
                })
            }
        } else {
            navigate("/spsharing")
            if (notification.Status === "new") {
                let formData = {
                    Message: notification.Message.Message,
                    Status: "processed",
                    ExternalID: notification.ExternalID
                }
                const entityType = "serviceprovider"
                UpdateNotification(entityType, notification.id, formData).then((response) => {
                    console.log("The Response is : ", response)
                }).catch((err) => {
                    console.log("The Error is : ", err)
                    setShowAlert(true);
                    setAlertText("Error fetching data from server.Please try again");
                })
            }

        }
        }

        const handlerOnBenClick=(notification)=>{
            navigate("/spsharing")
                if (notification.Status === "new") {
                    let formData = {
                        Message: notification.Message.Message,
                        Status: "processed",
                        ExternalID: notification.ExternalID
                    }
                    const entityType = "beneficiary"
                    UpdateNotification(entityType, notification.id, formData).then((response) => {
                        console.log("The Response is : ", response)
                    }).catch((err) => {
                        console.log("The Error is : ", err)
                        setShowAlert(true);
                        setAlertText("Error fetching data from server.Please try again");
                    })
                }
            }
            useEffect(()=>{
                if(isMarkSP === true){
                    GetServiceProviderNotifications()
                }

            },[isMarkSP])

            useEffect(()=>{
               if(isMarkBen === true){
                GetBeneficiaryNotifications()
            }
            },[isMarkBen])
            const deleteSPNotification=(notification)=>{
                const entityType="serviceprovider";
                DeleteNotification(entityType,notification.id).then((response)=>{
                    console.log("The Response is : ",response)
                    setIsSPNotificationDeleted(true)
                }).catch((error)=>{
                    console.log("The Error is : ",error)
                    setShowAlert(true);
                    setAlertText("Error Occurred while deleting Notification")
                })
            }

            const deleteBenNotification=(notification)=>{
                const entityType="beneficiary";
                DeleteNotification(entityType,notification.id).then((response)=>{
                    console.log("The Response is:",response)
                    setIsBenNotificationDeleted(true)
                }).catch((error)=>{
                    console.log("The Error is : ",error)
                    setShowAlert(true);
                    setAlertText("Error Occurred while deleting Notification")
                })
            }

            useEffect(()=>{
                
             if(isSPNotificationDeleted === true){
                GetServiceProviderNotifications()
             }
            },[isSPNotificationDeleted])

            useEffect(()=>{
                
                if(isBenNotificationDeleted === true){
                    GetBeneficiaryNotifications()
                }
            },[isBenNotificationDeleted])
    return (

        <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">

            <h1 className="bold-text-input">Notifications</h1>
             <div id="accordion-collapse" data-accordion="collapse" className="py-5">

               <h2 id="accordion-collapse-heading-1">
                <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                <div className='flex justify-items-center items-center gap-2'>
                    <span className='text-lg font-bold'>{`Service Provider Notification ${sortedSPNotifications !== undefined && sortedSPNotifications !== null ? sortedSPNotifications.length : 0}`}</span>
                    {spNotificationsCount > 0 && (<span className='text-xs font-medium me-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400'>{spNotificationsCount + " " + "new"}</span>)}
                </div>
                <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-1") == null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" : "w-3 h-3 shrink-0" } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
                </button>
               </h2>
               <div id="accordion-collapse-body-1" class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-1") == null ? "hidden" : localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-1")} aria-labelledby="accordion-collapse-heading-1">
                 <div className='p-5 border border-gray-700'>
                 {sortedSPNotifications === undefined || sortedSPNotifications.length === 0 ? <>No Notifications</> :
                  <ul className='w-full divide-y divide-gray-700'>
                    {
                        sortedSPNotifications?.map((notification) => {
                            return (
                                <li className='py-2' key={notification.id}>
                                    {
                                        notification.Status === "new" ? (
                                            <>
                                            <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">
                                                    <div className="order-2 md:order-1 flex-1">
                                                        {
                                                            notification?.Message?.Message?.length > 0 ?
                                                                (
                                                                    <p className='text-sm font-medium text-white'>{notification.Message.Message}</p>
                                                                ) : (
                                                                    <p className='text-sm font-medium text-white'>{notification.Message}</p>
                                                                )
                                                        }
                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                    <NotificationStatus status={notification.Status} />
                                                    <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                    </div>
                                                    </div>

                                                <div className="flex items-center justify-between">
                                                {
                                                         notification?.Message?.Tags === undefined && <button className='text-blue-500 bg-slate-333 hover:bg-555 hover:underline' onClick={(e)=>{handlerOnMarkSP(notification);saveCurrentState(e)}}>Mark as Read</button>
                                                }
                                                {
                                                notification?.Message?.Tags !== undefined && notification?.Message?.Tags?.RequestStatus?.Value !== null &&
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnSPClick(notification, notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value);saveCurrentState(e)}}>
                                                        <AiOutlineProfile className="inline-icon" />
                                                        View Address
                                                       </button>
                                                 }
                                                    {
                                                        notification?.Message?.Tags !== undefined && notification?.Message?.Tags?.RequestStatus?.Value === null &&  
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnSPClick(notification,notification?.Message?.Tags?.RequestId?.Value, notification?.Message?.Tags?.AddressId?.Value);saveCurrentState(e)}}>
                                                    <AiOutlineShareAlt className="inline-icon" />
                                                        View Shares
                                                    </button>
                                                    }
                                                    <div class="py-3 grid grid-cols-1 justify-items-end justify-end  rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={(e)=>{deleteSPNotification(notification);saveCurrentState(e)}}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                            </div>

                                            </>
                                            ) : (
                                            <div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">

                                                    <div className="order-2 md:order-1 flex-1">

                                                        <p className='text-sm font-medium text-white'>{notification.Message}</p>

                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                    <NotificationStatus status={notification.Status} />
                                                    <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                    </div>
                                                    </div>
                                                    <div class="py-3 grid grid-cols-1 justify-items-end justify-end rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={(e)=>{deleteSPNotification(notification);saveCurrentState(e)}}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                    </div>

                                        )
                                    }
                                </li>
                            )

                        })
                    }

                    </ul>
                  }
                    </div>
                </div>
                <h2 id="accordion-collapse-heading-2">
              <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-2" onClick={handleAccordionClick}>
              <div className='flex justify-items-center items-center gap-2'>
                <span className='text-lg font-bold'>{`Beneficiary Notification ${sortedBenNotifications !== undefined && sortedBenNotifications !== null ? sortedBenNotifications.length : 0}`}</span>
                {benNotificationsCount > 0 && (<span className='text-xs font-medium me-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400'>{benNotificationsCount + " " + "new"}</span>)}
                </div>
                <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-2") === null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" :  "w-3 h-3 shrink-0" } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
              </button>
               </h2>

               <div id="accordion-collapse-body-2" class={localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-2") == null ? "hidden" : localStorage.getItem(document.location.hostname + ".notification.accordion.accordion-collapse-body-2")} aria-labelledby="accordion-collapse-heading-2">
                 <div className='p-5 border border-gray-700'>
                 {sortedBenNotifications == undefined || sortedBenNotifications.length === 0 ? <>No Notifications</> :
                  <ul class="w-full divide-y divide-gray-700">
                    {
                        sortedBenNotifications?.map((notification) => {
                            return (
                                <li className='py-2' key={notification.id}>
                                    {
                                        notification.Status === "new" ? (
                                            <div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">
                                                    <div className="order-2 md:order-1 flex-1">
                                                        {
                                                            notification?.Message?.Message?.length > 0 ?
                                                                (
                                                                    <p className='text-sm font-medium text-white'>{notification.Message.Message}</p>
                                                                ) : (
                                                                    <p className='text-sm font-medium text-white'>{notification.Message}</p>
                                                                )
                                                        }
                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                    <NotificationStatus status={notification.Status} />
                                                    <time class="md:px-2 mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>
                                                    </div>

                                                    </div>

                                                <div className="flex items-center justify-between">
                                                {
                                                       notification?.Message?.Tags !== undefined &&   
                                                        <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline" onClick={(e) => {handlerOnBenClick(notification);saveCurrentState(e)}}>
                                                    <AiOutlineShareAlt className="inline-icon" />
                                                        View Shares
                                                    </button>
                                                }
                                                {
                                                  
                                                  notification?.Message?.Tags === undefined && <button className='text-blue-500 bg-slate-333 hover:bg-555 hover:underline' onClick={(e)=>{handleOnMarkBen(notification);saveCurrentState(e)}}>Mark as Read</button>
                                                        

                                                }
                                                <div class="py-3 grid grid-cols-1 justify-items-end justify-end  rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={(e)=>{deleteBenNotification(notification);saveCurrentState(e)}}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>
                                                    
                                                </div>

                                            </div>


                                        ) : (
                                            <div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between gap-2">

                                                    <div className="order-2 md:order-1 flex-1">

                                                        <p className='text-sm font-medium text-white'>{notification.Message}</p>

                                                    </div>
                                                    <div className="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:items-center rtl:space-x-reverse gap-2">
                                                    <NotificationStatus status={notification.Status} />
                                                    <time class="text-xs font-normal text-gray-400" title={ConvertUnixTimestampToDateString(notification.Created)}>{dayjs().to(ConvertUnixTimestampToDateString(notification.Created))}</time>

                                                    </div>
                                                    </div>
                                                    <div class="py-3 grid grid-cols-1 justify-items-end justify-end rtl:space-x-reverse">
                                                        <button className="bg-slate-333 py-1 hover:bg-555" onClick={(e)=>{deleteBenNotification(notification);saveCurrentState(e)}}>
                                                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                            </button>
                                                            </div>

                                            </div>

                                        )
                                    }
                                </li>
                            )

                        })
                    }

                  </ul>
                 }   
                 </div>
                 </div>
                 </div>
            </div>
    )
}

function NotificationStatus({ status, title }) {
    switch (status) {
        case "processed":
            return <BsCheckCircleFill fill="green" title="Read" />;
        case "new":
            return <BsCircle fill="green" title="Unread" />;
        default:
            return "";
    }
}
function handleAccordionClick(ev){
    let dv, icon;
    if(ev.target.dataset && ev.target.dataset.accordionTarget){
      dv = document.getElementById(ev.target.dataset.accordionTarget);
      icon = ev.target.querySelector("svg");
    } else {
      const button = ev.target.closest("button");
      dv = document.getElementById(button.dataset.accordionTarget);
      icon = button.querySelector("svg");
    }
    if(dv.classList.contains("hidden")) {
      localStorage.setItem(document.location.hostname + '.notification.accordion.' + dv.id, 'block' )
  
      dv.classList.add("block"); 
      dv.classList.remove("hidden");
      icon.classList.remove("rotate-180");
    } else {
      localStorage.setItem(document.location.hostname + '.notification.accordion.' + dv.id, 'hidden' )
  
      dv.classList.add("hidden");
      dv.classList.remove("block");
      icon.classList.add("rotate-180");
    }
  }

export default ServiceProviderNotifications
