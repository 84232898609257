import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const FilteredList = ({ className, initialListProp, onChange }) => {
  const initialList = initialListProp || [];
  const [inputValue, setInputValue] = useState('');
  const[openDialog,setOpenDialog]=useState(true);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setOpenDialog(true)
  };

  const handleSelectOption = (selectedValue) => {
    setInputValue(selectedValue);
    setOpenDialog(false)
  };

 useEffect(() => {
    // Execute the onChange function when inputValue changes
    if (onChange) {
      onChange(inputValue);
    }
  }, [inputValue]);

  return (
    <div className={`relative ${className}`}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter Email or choose from below list..."
        className={className}
      />
      {openDialog && initialList && initialList.length > 0 && (
        <ul className={`absolute top-3/4 -translate-x-1.5 max-h-24 overflow-auto ${className}`}>
          {
          initialList?.map((item,index)=>(
                 <li key={index} className={`${className} cursor-pointer`} onClick={()=>handleSelectOption(item.value ? item.value : item.email)}>{item.label ? item.label : item.name + '(' + item.email + ')'}</li>
                ))
                }
                </ul>
                )
        
      }
    </div>
  );
};

FilteredList.propTypes = {
  className: PropTypes.string,
  initialListProp: PropTypes.arrayOf({ value: PropTypes.string,
                                     label: PropTypes.string,}),
  onChange: PropTypes.func,
};

export default FilteredList;
